import React from "react";
import Headerback from "../layouts/Headerback";
import { useNavigate } from "react-router-dom";
const AgregarMetodo = () => {
    const navigate = useNavigate();
    const hadleGoAgregarTarjeta = () => {
        navigate("/home/AgregarTarjeta");
    }
  return (
    <div className="container">
      <div>
        <Headerback title="" />
      </div>
      <div className="row">
        <div className="col mt-5 inter_20_normal left ml-2 ">
          <span> Agregar metodo de pago </span>
        </div>
      </div>
      <div>
        <div className="container cardPago mt-4">
          <div className=" row mt-4 mb-4 d-flex">
            <p className="col-2 ml-2 ">
              <img
                width="48"
                height="48"
                src="https://img.icons8.com/cotton/64/add-credit-card.png"
                alt="add-credit-card"
                style={{ border: "1px solid #D9D9D9", borderRadius: "30px" }}
              />
            </p>
            <p className="col inter_14_gray left mt-3 ">
              Credito O Debito (con CVV)
            </p>
            <p className="col-1 mr-2 align-items-center">
              <img
                width="20"
                height="20"
                src="https://img.icons8.com/fluency/48/forward--v1.png"
                alt="forward--v1"
                onClick={hadleGoAgregarTarjeta}
              />
            </p>
          </div>
          <hr />
          <div className=" row mt-2  mb-4">
            <p className="col-3 d-flex">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/color/48/visa.png"
                alt="visa"
                className=" ml-3"
              />
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/color/48/amex.png"
                alt="amex"
                className=" ml-2"
              />
               <img
                width="30"
                height="30"
                src="https://img.icons8.com/color/48/mastercard.png"
                alt="mastercard"
                className=" ml-2"
              />
               <img
                width="30"
                height="30"
                src="https://img.icons8.com/ultraviolet/40/diners-club.png"
                 alt="diners-club"
                className=" ml-2"
              />
            </p>
    
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgregarMetodo;
