import React, { useEffect, useState } from 'react';
import CardComponent from '../layouts/CardComponent';
import Cupones from '../layouts/Cupones';
import { useNavigate } from 'react-router-dom';
import ColorPages from '../layouts/Colorpages';
import { getdata, getsingle } from '../../apiService';
import numeral from 'numeral';
import Headerback from '../layouts/Headerback';
import ItemProductBono from '../layouts/ItemProductBono';

const RedimirPuntos = () => {

  const navigate = useNavigate();
  const [userPoints, setUserPoints] = useState({ total: 0, name: "" });
  const [destacados, setDestacados] = useState([]);
  const [destacadostwo, setDestacadostwo] = useState([]);
  const fetchData = async () => {
    try {

      const userPointsData = await getsingle("user_points_v2");
      setUserPoints(userPointsData);

      const homeresponse = await getdata("bonosugeridos");
      setDestacados(homeresponse.destacados.productsone);
      setDestacadostwo(homeresponse.destacados.productstwo);

    } catch (error) {
      console.error(error);
    }
    return () => {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  const goSolictarpuntos = (event) => {
    navigate("/home/Solicitarpuntos")
  }




  return (
    <div>
      <ColorPages />
      <Headerback title="" />
      <div className='page pb-4 '>
        <div className='px-4 mt-3'>
          <CardComponent
            puntos={numeral(userPoints.total).format('0,0')}
            usuario={userPoints.name}
            puntosTexto="Mis puntos"

          />
        </div>

        <div className=' px-4'>
          <div className="card_gray_light mt-4 p-0 py-4">
            <div className='px-4'>
              <div className='w-100'>
                <div className='col-12'><p className='inter_14_600 left color-primary'>1 PUNTO FLEKSI = 1 PESO COP</p></div>
              </div>

              <div className='row mt-2'>
                <div className='col-6'> <p className='inter_12 gray-dark'>Puntos por confirmar:</p></div>
                <div className='col-6'> <p className='inter_12 gray-dark'>{numeral(userPoints.total_points_verified).format('0,0')}  puntos</p></div>
              </div>

              <div className='w-100 mt-2'  >
                <div className='col-12'>
                  <p className='inter_12_400 gray-dark w-100  left'>Estos puntos están en validación, los recibirás a más tardar en 15 días hábiles luego de la compra.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=' px-4 mt-4' onClick={goSolictarpuntos}>
          <div className="card_main px-4 py-4">
            <div className="row p-0 m-0">
              <div className="col-2 p-0 left">
                <img
                  src={process.env.PUBLIC_URL + '/images/percentaje.png'}
                  alt="Fleksi Logo"
                  style={{ width: 'auto', height: '30px' }} />

              </div>    <div className="col p-0 left">
                <p className='inter_16_500 color-white pl-2 pt-1'>Convertir puntos
                  en dinero</p>
              </div>



            </div>
          </div>
        </div>

        <div style={{ marginTop: "52px" }}  >
          <ItemProductBono title="Canjear por cupones" data={destacados} />
          {destacadostwo.length > 0 && <ItemProductBono title="" data={destacadostwo} />}
        </div>

      </div>

    </div>
  );
};

export default RedimirPuntos;
