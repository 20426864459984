import React, { useState } from 'react';
import HeaderTitle from '../layouts/HeaderTitle';
import TabLayout from '../layouts/TabLayout';
import ColorPages from '../layouts/Colorpages';

const Ayuda = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    // Lógica para obtener las sugerencias basadas en el término de búsqueda
    const newSuggestions = getSearchSuggestions(value);
    setSuggestions(newSuggestions);
    setShowSuggestions(value !== '');
  };

  const getSearchSuggestions = (value) => {
    const suggestions = Array.from({ length: 50 }, (_, index) => `Ayuda ${index + 1}`);
    return suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );
  };

  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null; // Ocultar la lista cuando no se debe mostrar
    }

    return (
      <ul className="suggestions-list">
        {suggestions.map((suggestion) => (
          <li key={suggestion} onClick={() => setShowSuggestions(false)}>
            {suggestion}
          </li>
        ))}
      </ul>
    );
  };



  const [activeTabs, setActiveTabs] = useState([0]);

  const handleTabClick = (index) => {
    if (activeTabs.includes(index)) {
      setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index));
    } else {
      setActiveTabs([...activeTabs, index]);
    }
  };

  return (
    <div className='app'>
         <ColorPages/>
      <HeaderTitle title="" />
      <div className='page'>
        <p className='inter_20_500'>Hola , ¿Cómo podemos ayudarte?</p>

        <div className="center-div px-5 ">
          <div className="search-container">
            <span className="search-icon">
              <img src={process.env.PUBLIC_URL + '/images/buscar.png'} alt="Buscar" />
            </span>
            <input
              style={{ marginTop: '10px', paddingLeft: '40px' }}
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              className="form-input form_rounded"
              placeholder="Buscar..."
            />
          </div>
        </div>


        <div className="tab-layout">
          <div className="tab-navigation">
            <ul>
              <TabLayout
                title="Problemas la registrar tu cuenta bancaria"
                description="Contenido del Tab 1 - Lorem ipsum dolor sit amet"
                active={activeTabs.includes(0)}
                handleTabClick={() => handleTabClick(0)}
              />
              <TabLayout
                title="Problemas compartiendo tu código"
                description="Contenido del Tab 2 - Lorem ipsum dolor sit amet"
                active={activeTabs.includes(1)}
                handleTabClick={() => handleTabClick(1)}
              />
                           <TabLayout
                title="Como canjear Fleksi puntos"
                description="Contenido del Tab 2 - Lorem ipsum dolor sit amet"
                active={activeTabs.includes(1)}
                handleTabClick={() => handleTabClick(1)}
              />
              <TabLayout
                title="Otro"
                description="Contenido del Tab 3 - Lorem ipsum dolor sit amet"
                active={activeTabs.includes(2)}
                handleTabClick={() => handleTabClick(2)}
              />
        
            </ul>
          </div>
        </div>
        {renderSuggestions()}
      </div>
    </div>
  );
};

export default Ayuda;
