import React from 'react';
import { useNavigate } from 'react-router-dom';

const ItemProductExternal = ({ title, data }) => {
  const navigate = useNavigate();
  const handleCardClick = (code) => {
    const randomNumber = Math.floor(Math.random() * 1000);
    navigate(`/home/ProductoExternal?code=${code}&rand=${randomNumber}`);
    window.location.reload();

  };
  return (
    <div className="container mt-0 pt-0">
      {data.length > 0 && <div className="inter_14 gray-dark left ml-2">{title}</div>}
      <div className="container-inline mt-3 w-100">
        {data.map((item) => (
          <div className="col-4 m-2 card_back center" key={item.id_fleksi_products} onClick={() => handleCardClick(item.id_fleksi_products)}>
            <div className="card-products center" >
              <div className="img-top-round">
                <img src={item.image} className="" alt="Imagen" />
              </div>
              <span className="overlay-text bg-blue color-white">Tienda fisica</span>
            </div>
            <span className="inter_14_600 gray-dark mt-2 px-2  w-100 left  rm-2" style={{
              maxWidth: '200px',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>{item.ecommerce}</span>
            <span className="inter_12 color-green px-2 w-100 left rm-5" style={{
              maxWidth: '200px',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {item.subtitle}
            </span>

            <button className='button-primary-w mt-2' >Conoce más</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemProductExternal;
