import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postarray } from "../../apiService";
import ImageComponent from "./ImageComponent";
import { Link } from "react-router-dom";
import useCart from "../utils/useCart";

const HeaderBusquedaHome = ({ title }) => {
  const navigate = useNavigate();
  const { addToCart, total_cart } = useCart();
  const [totalcart, setTotalcart] = useState(0);

  useEffect(() => {
    setTotalcart(total_cart());
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCardClick = (code, id_type_of_shops) => {
    const randomNumber = Math.floor(Math.random() * 1000);
    setShowSuggestions(false);

    if ([6, 1, 4, 5].includes(id_type_of_shops)) {
      navigate(`/home/EcommerceProducto?code=${code}&rand=${randomNumber}`);
    }
    if (id_type_of_shops === 3) {
      navigate(`/home/Bono?code=${code}&rand=${randomNumber}`);
    }
    if (id_type_of_shops === 2) {
      navigate(`/home/TiendaProductoFisico?code=${code}&rand=${randomNumber}`);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [resultados, setResultados] = useState([]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    try {
      const response = await postarray(
        { searchTerm: value },
        "searchallproducts"
      );
      setSuggestions(response.results);
      setShowSuggestions(value !== "");
    } catch (error) {
      console.error(error);
    }
  };

  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }

    return (
      <ul className="suggestions-list mt-2">
        {suggestions.map((suggestion, index) => (
          <li
            className="left"
            key={index}
            onClick={() =>
              handleCardClick(
                suggestion.id_fleksi_products,
                suggestion.id_type_of_shops
              )
            }
          >
            <ImageComponent
              src={suggestion.image}
              alt={suggestion.title}
              defaultSrc={process.env.PUBLIC_URL + "/images/defaultimage.png"}
              cssclass={"img-search"}
            />

            <span
              className="inter_12_500 ml-2"
              style={{ marginTop: "15px", position: "absolute" }}
            >
              {" "}
              {suggestion.title}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const handleGogocart = () => {
    navigate("/home/Cart");
  };

  return (
    <div
      className="w-100 bg-white pb-lg-0 pb-3 "
      style={{ position: "fixed", top: 0, zIndex: 999 }}
    >
      <div className="banner ">
        <div className="left-div pt-3">
          <Link to="Menu" className="link-no-underline">
            <img
              src={process.env.PUBLIC_URL + "/images/main.png"}
              alt="Slider 1"
              style={{ width: "28px", height: "auto" }}
              className="hamburger-icon"
            />
          </Link>
        </div>
        <div className="logo-left-div">
          <img
            src={
              process.env.PUBLIC_URL +
              "  https://fleksiimg.s3.amazonaws.com/aliados/logos/Logo_fleksi.png"
            }
            alt="Logo"
            className="logo-icon"

            // Añadir clase para el logo
          />
        </div>

        <div className="center-div">
          <div className="search-container">
            <span className="search-icon">
              <img
                src={process.env.PUBLIC_URL + "/images/buscar.png"}
                alt="Buscar"
              />
            </span>
            <input
              style={{ marginTop: "5px", paddingLeft: "40px" }}
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              className="form-input form_rounded"
              placeholder="Buscar aquí"
            />
          </div>
        </div>
        <div className="right-div  pt-3" style={{ width: "80px" }}>
          <Link to="Menu">
            <img
              src={process.env.PUBLIC_URL + "/images/user_blanco.png"}
              alt="Slider 1"
              style={{ width: "32px", height: "auto" }}
              className="user-icon"
            />
          </Link>
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={handleGogocart}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/cart.png"}
              alt="Slider 1"
              className="cart-icon"
              style={{ width: "28px", height: "auto" }}
            />
            <span className="cantidad" id="cantidad">
              {totalcart}
            </span>
          </div>
        </div>
        {renderSuggestions()}
      </div>
      <div className="row d-none d-md-block">
        {/*<hr style={{
  border: 'none',
  borderTop: '2px solid transparent',
  width: '70%',
  marginLeft: '20%',
  borderImage: 'linear-gradient(to right, white, var(--primary-color), white) 1',
  marginBottom: '0px',
  className: 'd-none d-md-block' 
}} />*/}
        <div className="nav-links">
          <Link className="col" to="/home">
            Home
          </Link>
          <Link className="col" to="/Home/HistorialCompras">
            Historial de compras
          </Link>
          <Link className="col" to="/Home/HistorialPuntos">
            Fleksi puntos
          </Link>
          <Link className="col" to="/Home/DatosBancarios">
            Datos bancarios
          </Link>
          <Link className="col" to="/Home/InvitarAmigo">
            Invitar a un amigo
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderBusquedaHome;
