import React, { useEffect, useState } from 'react';
import ItemProductTienda from '../layouts/ItemProductTienda';
import { useNavigate, useLocation } from 'react-router-dom';
import Headerback from '../layouts/Headerback';
import { postarray } from '../../apiService';
import ImageComponent from '../layouts/ImageComponent';
import TabLayoutLocation from '../layouts/TabLayoutLocation';
import TabLayout from '../layouts/TabLayout';
import numeral from 'numeral';
import ColorPages from '../layouts/Colorpages';
import ItemProduct from '../layouts/ItemProduct';

const ProductoExternal = () => {
    const [bono, setBono] = useState({});
    const [related_products, setRelated_products] = useState([]);
    const [related_productstwo, setRelated_productstwo] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const [activeTabs, setActiveTabs] = useState([0, 4]);
    const navigate = useNavigate();
    const goubicaciones = (image,id_fleksi_products) => {
        localStorage.setItem('ProductoExternalImage',image)
        localStorage.setItem('ProductoExternalid_fleksi_products',id_fleksi_products);        
        navigate(`/home/EndTiendaExternal`);
    };

    const fetchData = async () => {
        try {
            const TiendaFisicaResponse = await postarray({ "id_fleksi_products": code }, "productoexternal");

            setBono(TiendaFisicaResponse);
            setRelated_products(TiendaFisicaResponse.related_products.productsone);
            setRelated_productstwo(TiendaFisicaResponse.related_products.productstwo);

        } catch (error) {
            console.error(error);
        }
        return () => {}
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleTabClick = (index) => {
        if (activeTabs.includes(index)) {
            setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index));
        } else {
            setActiveTabs([...activeTabs, index]);
        }
    };


    return (
        <div>
            <ColorPages />
            <Headerback title= {bono.ecommerce}/>
            <div className='page px-4'>
                <div className='mt-2 center' style={{ height: "250px" }}>

                    <ImageComponent
                        src={bono.image}
                        alt={bono.title}
                        defaultSrc={process.env.PUBLIC_URL + '/images/defaultimage.png'}
                        cssclass={'img-bono'} />
                </div>
                <div className='mt-2 col-12 row mt-4 '>
                    <div className='col'>
                        <p className='inter_18_600 left gray-dark'>{bono.title}</p>
                    </div>
         
                </div>

                <div className='row m-0 '>
                <p className='inter_12 left p-0 mt-2 gray-dark'> {bono.subtitle}</p>
                </div>

                <div className="tab-layout" >
                    <div className="tab-navigation p-0 m-0 ">
                        <ul>
                            {bono.description && (
                                <TabLayout
                                    key={0}
                                    title="Descripción"
                                    description={bono.description}                                 
                                    active={activeTabs.includes(0)}
                                    handleTabClick={() => handleTabClick(0)}
                                    Link_video={bono.Link_video}
                                    Link={bono.Link}
                                    ButtonText={bono.Buttontext}
                                />
                                
                            )}

                            {bono?.locations && Object.keys(bono.locations).length > 0 && (
                                <TabLayoutLocation
                                    title="Ubicaciones"
                                    active={activeTabs.includes(4)}
                                    handleTabClick={() => handleTabClick(4)}
                                    locations={bono.locations}
                                    code={code}
                                    go='UbicacionesExternal'
                                />
                            )}


                            {bono.benefits && (
                                <TabLayout
                                    key={3}
                                    title="Beneficios"
                                    description={bono.benefits}
                                    active={activeTabs.includes(2)}
                                    handleTabClick={() => handleTabClick(2)}
                                />
                            )}
                            {bono.terms_and_conditions && (
                                <TabLayout
                                    key={4}
                                    title="Términos y condiciones"
                                    description={bono.terms_and_conditions}
                                    active={activeTabs.includes(3)}
                                    handleTabClick={() => handleTabClick(3)}
                                />
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "32px" }}>                          
     {bono.Link_terminos && (
      <a  style={{ marginTop: "32px" }} href={bono.Link_terminos}>Términos y condiciones</a>
     )}</div>

            <div style={{ marginTop: "32px" }}>
                <ItemProduct title={"Productos que te podrían gustar"} data={related_products} />
                <ItemProduct title={""} data={related_productstwo} />
            </div>
        </div>
    );
};


export default ProductoExternal;
