import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ItemComercios from '../layouts/ItemComercios';
import ItemProduct from '../layouts/ItemProduct';
import ModalDocument from '../layouts/ModalDocument';
import { Link } from 'react-router-dom';
import { getdata, getsingle } from '../../apiService';
import CategorySection from '../layouts/CategorySection';
import BannerSlider from '../layouts/BannerSlider';
import ColorPages from '../layouts/Colorpages';
import HeaderBusquedaHome from '../layouts/HeaderBusquedaHome';
import LoadingSpinner from '../layouts/LoadingSpinner';
import useCart from '../utils/useCart';
import { useNavigate } from 'react-router-dom';
import ModalDocumentTermsConditions from '../layouts/ModalDocumentTermsConditions';




const Home = () => {
  const navigate = useNavigate();

  
  const { addToCart, total_cart } = useCart();
  const [desing, setDesing] = useState({});
  const [banners, setBanners] = useState([]);
  const [categories, setcategories] = useState([]);
  const [categoriestwo, setcategoriestwo] = useState([]);
  const [userPoints, setUserPoints] = useState({ total: 0, name: "" });
  const [comercios, setComercios] = useState([]);
  const [comerciostwo, setComerciostwo] = useState([]);
  const [cashbackespecial, setCashbackespecial] = useState([]);
  const [lomasnuevo, setLomasnuevo] = useState([]);
  const [tepuedeinteresar, setTepuedeinteresar] = useState([]);

  const [cashbackespecialtwo, setCashbackespecialtwo] = useState([]);
  const [lomasnuevotwo, setLomasnuevotwo] = useState([]);
  const [tepuedeinteresartwo, setTepuedeinteresartwo] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Nueva variable de estado
  const [totalcart, setTotalcart] = useState(0);

  const [bonosconpuntos, setbonosconpuntos] = useState([]);
  const [bonosconpuntostwo, setbonosconpuntostwo] = useState([]);
  const [activatedTerms, setActivatedTerms] = useState(false);


  const fetchData = async () => {
    setTotalcart(total_cart())

    const desing = await getsingle("actual_program");
    setDesing(desing);

    if (desing == "Unauthorized guest") {

localStorage.removeItem("accessToken");
window.location.href = "/Session/Login";
    }else{
      localStorage.setItem('appDesign', JSON.stringify(desing));
      // Asignar los valores del objeto a las variables CSS

      localStorage.setItem("themecode", desing.name);
      document.documentElement.style.setProperty('--primary-color', desing.main_color);
      document.documentElement.style.setProperty('--splash-back-color', desing.color_back_splash);
      document.documentElement.style.setProperty('--spinner-color', desing.color_loading);

      localStorage.setItem("logo_superior_card", desing.logo_superior_card);
      localStorage.setItem("class_img_end_card", desing.class_img_end_card);
      localStorage.setItem("class_img_sup_card", desing.class_img_sup_card);
      localStorage.setItem("logo_inferior_card", desing.logo_inferior_card);
      localStorage.setItem("text_color_card", desing.text_color_card);
      localStorage.setItem("card_color", desing.card_color);
      localStorage.setItem("text_color_percentage", desing.text_color_percentage);
      localStorage.setItem("image_percentage", desing.image_percentage);
      localStorage.setItem("logo_splash", desing.logo_splash);
      localStorage.setItem("icon_notificacion", desing.icon_notificacion);

    const categoriesResponse = await getdata("fleksi_categories_v3");
    setcategories(categoriesResponse.c_one);
    setcategoriestwo(categoriesResponse.c_two);

    const bonosconpuntos = await getdata("bonosconpuntos")

    setbonosconpuntos(bonosconpuntos.destacados.productsone);
    setbonosconpuntostwo(bonosconpuntos.destacados.productstwo);

    const userPointsData = await getsingle("user_points_v2");
    setUserPoints(userPointsData);

    const bannersResponse = await getdata("banners_home");
    setBanners(bannersResponse.data);

    const homeresponse = await getdata("home_v3")
    setCashbackespecial(homeresponse.cashbackespecial.productsone);
    setLomasnuevo(homeresponse.lomasnuevo.productsone);
    setTepuedeinteresar(homeresponse.tepuedeinteresar.productsone);

    setbonosconpuntos(homeresponse.bonossugeridos.productsone);
    setbonosconpuntostwo(homeresponse.bonossugeridos.productstwo);

    setCashbackespecialtwo(homeresponse.cashbackespecial.productstwo);
    setLomasnuevotwo(homeresponse.lomasnuevo.productstwo);
    setTepuedeinteresartwo(homeresponse.tepuedeinteresar.productstwo);

    const comerciosReponse = await getdata("comercios_aliados_v3");
    setComercios(comerciosReponse.data.shops);
    setComerciostwo(comerciosReponse.data.shopstwo);

    const checkTermsAcceptance = await getdata("checkTermsAcceptance");
    setActivatedTerms(checkTermsAcceptance.code == 200 ? false : true);
    setIsLoading(false);
    return () => {}
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  //aqui el estado inicla del modal
  const [showModal, setShowModal] = useState(true);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleTerms = () => {
    setActivatedTerms(false);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    // Lógica para obtener las sugerencias basadas en el término de búsqueda
    const newSuggestions = getSearchSuggestions(value);
    setSuggestions(newSuggestions);
    setShowSuggestions(value !== '');
  };

  const getSearchSuggestions = (value) => {
    const suggestions = Array.from({ length: 50 }, (_, index) => `Texto ${index + 1}`);
    return suggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );
  };

  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }

    return (
      <ul className="suggestions-list">
        {suggestions.map((suggestion) => (
          <li key={suggestion} onClick={() => setShowSuggestions(false)}>
            {suggestion}
          </li>
        ))}
      </ul>
    );
  };

  const text_color_card = localStorage.getItem('text_color_card');
  const card_color = localStorage.getItem('card_color');

  return (
    <div className='container-fluid p-0'>
      <ColorPages />
      {isLoading && <LoadingSpinner />}

      {showModal && <ModalDocument activated={true} handleCloseModal={handleCloseModal} />}
      {activatedTerms && <ModalDocumentTermsConditions activated={activatedTerms} handleCloseModal={handleTerms} />}

      {renderSuggestions()}
      <HeaderBusquedaHome />
      
      <div className='page'>
        <BannerSlider banners={banners} />
        <Link to="Mibilletera" className="link-no-underline" >
          <div className="card_home" sty={{ backgroundColor: card_color }} >
            <div className="w-75 ">
              <div className="inter_18 left w-100  l -4 " style={{ color: text_color_card }}>Mi billetera</div>
              <div className="inter_18 left w-100 Card_billetera" style={{ color: text_color_card }}>Hola, {userPoints.name}</div>
            </div>
            <div className="grid ">
              <span className="inter_18" style={{ color: text_color_card }}>{userPoints.total}</span>
              <span className="inter_12_400 color-white " style={{ color: text_color_card }}>Mis puntos</span>
            </div>
          </div>
        </Link>

        <div className='mt-4'>
          <CategorySection categories={categories} title="Categorías" />
          {categoriestwo.length > 0 && <CategorySection categories={categoriestwo} title='' />}
        </div>
        <div style={{ marginTop: "20px" }}  >
          <ItemComercios title="Comercios aliados" data={comercios} />
          {comerciostwo.length > 0 && <ItemComercios title="" data={comerciostwo} />}
        
        </div>
        <div style={{ marginTop: "10px" }}>
          <ItemProduct title="🌟¡Usa tus puntos para comprar Bonos digitales ahora!" data={bonosconpuntos} />
          {bonosconpuntostwo.length > 0 && <ItemProduct title="" data={bonosconpuntostwo} />}

          <ItemProduct title="Cashbacks especiales para ti" data={cashbackespecial} />
          {cashbackespecialtwo.length > 0 && <ItemProduct title="" data={cashbackespecialtwo} />}

          <ItemProduct title="Te puede interesar" data={tepuedeinteresar} />
          {tepuedeinteresartwo.length > 0 && <ItemProduct title="" data={tepuedeinteresartwo} />}
          <ItemProduct title="Lo mas nuevo" data={lomasnuevo} />
          {lomasnuevotwo.length > 0 && <ItemProduct title="" data={lomasnuevotwo} />}
        </div>
      </div>
    
    </div>
  );
};

export default Home;
