import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
    apiKey: "AIzaSyDOQzXakac4NBzXG2D0POgSvmHivNTXOJw",
    authDomain: "fleksi-24f8f.firebaseapp.com",
    projectId: "fleksi-24f8f",
    storageBucket: "fleksi-24f8f.appspot.com",
    messagingSenderId: "356390905973",
    appId: "1:356390905973:web:7f1a0d65183a09e004703c",
    measurementId: "G-LMT6PYZ553"
  };

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app);
const auth = getAuth(app)

export {auth}




