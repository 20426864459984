import React, { useEffect, useState } from 'react';
import HeaderTitle from '../layouts/HeaderTitle';
import CardComponentVertical from '../layouts/CardComponentVertical';
import { useNavigate } from 'react-router-dom';
import ColorPages from '../layouts/Colorpages';
import { getsingle } from '../../apiService';
import numeral from 'numeral';
import Headerback from '../layouts/Headerback';

const Membresia = () => {
  const navigate = useNavigate();
  const [userPoints, setUserPoints] = useState({ total: 0, name: "" });

  const fetchData = async () => {
    try {

      const userPointsData = await getsingle("user_points_v2");
      setUserPoints(userPointsData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const goredempoints = (event) => {
    navigate("/home/Mibilletera")
  }


  return (
    <div>
      <ColorPages />
      <Headerback title="" />
      <div className='page'>
        <div className='px-4 mt-3'>
          <CardComponentVertical     
            puntos=   {numeral(userPoints.total).format('0,0')} 
            usuario={userPoints.name}
            apellido={userPoints.surname}
            cedula={userPoints.document}
            puntosTexto="MIS PUNTOS"
          />
        </div>

        <p className='inter_12 gray pt-4 px-4'>Recuerda presentar este carné en nuestros comercios aliados para agregar puntos por tus compras.</p>

        <div className='center mt-3 w-100  '>
          <button type="button" onClick={goredempoints} className=" btnRedimir button-primary mt-3 w-75">Redimir puntos</button>
        </div>


      </div>
    </div>
  );
};

export default Membresia;
