import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderTitle from '../layouts/HeaderTitle';
import CardComponentVerticalsmall from '../layouts/CardComponentVerticalsmall';
import ColorPages from '../layouts/Colorpages';
import Headerback from '../layouts/Headerback';
import { getsingle } from '../../apiService';
import numeral from 'numeral';

const EndTienda = () => {
    const navigate = useNavigate();
    const [userPoints, setUserPoints] = useState({ total: 0, name: "" });

    const gobeneficios = (transactionId) => {
        // Realiza la acción deseada al hacer clic en el elemento
        const randomNumber = Math.floor(Math.random() * 1000);
        navigate(`/home/Endbeneficios?code=${localStorage.getItem('TiendaProductoFisicoid_fleksi_products')}&rand=${randomNumber}`);
    };

    const goubicaciones = () => {  const randomNumber = Math.floor(Math.random() * 1000);
        navigate(`/home/Ubicaciones?code=${localStorage.getItem('TiendaProductoFisicoid_fleksi_products')}&rand=${randomNumber}`);
    };

    const fetchData = async () => {
        try {
  
          const userPointsData = await getsingle("user_points_v2");
          setUserPoints(userPointsData);
        } catch (error) {
          console.error(error);
        }
        return () => {}
      };

    useEffect(() => {
        fetchData();
      }, []);
     

    return (
        <div>
            <Headerback title="" />
            <ColorPages />
            <div className='mt-2 center' style={{ height: "300px" }}>
                <img
                    src={localStorage.getItem('TiendaProductoFisicoImage')}
                    alt=""
                    style={{ width: "auto", height: "100%", objectFit: "contain",  maxWidth: "80%" }}
                /></div>

            <div className=' px-4  py-4'>
                <div>
                    <CardComponentVerticalsmall
                        puntos={numeral(userPoints.total).format('0,0')}
                        usuario={userPoints.name}
                        apellido={userPoints.surname}
                        cedula={userPoints.document}
                        puntosTexto="MIS PUNTOS" />
                </div>

                <p className='inter_12_400 px-3 mt-3 gray'>
                    Recuerda presentar este carné o cédula al momento de tu compra para reclamar tus beneficios
                </p>

                <div className='center mt-4'>
                    <button type="button" onClick={() => gobeneficios(1)} className="button-primary">Conoce tus beneficios</button>
                </div>

                <div className="center-content mt-4 mb-4">
                    <button type="button" onClick={goubicaciones} className="button-secundary-login  button-text bg-white">Conoce ubicaciones</button>
                </div>
            </div>
        </div>
    );
};

export default EndTienda;
