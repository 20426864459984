import React from 'react';
import { useNavigate } from 'react-router-dom';
import numeral from 'numeral';

const ItemProductRefacil = ({ title, data }) => {
  const navigate = useNavigate();
  const handleCardClick = (id_fleksi_products,id_type_of_shops) => {

    
    navigate(`/home/RefacilItem?code=${id_fleksi_products}&type=${id_type_of_shops}`);    
  };

  return (
    <div className="container mt-0 pt-0 my-0 py-0">
      {data.length > 0 && <div className="inter_14 gray-dark left ml-2">{title}</div>}
      <div className="container-inline mt-3 w-100">
        {data.map((item) => {

          const imageUrl = item.image.replace(/\\/g, '');
          return (<div className="col-4 m-2 card_back center" key={item.id_fleksi_products} onClick={() => handleCardClick(item.id_fleksi_products, item.id_type_of_shops)}>
            <div className="card-products center" style={{ backgroundColor: item.background }}>
              <div className="img-top-round" >
                <img src={imageUrl} className="" alt="Imagen" style={{ width: '100%', height: '90%', objectFit: 'cover' }} />
              </div>

              {item.id_type_of_shops == "8" && (
                <>
                  <span className="overlay-text bg-yellow gray-dark">Pines</span>

                </>
              )}

              {item.id_type_of_shops == "9" && (
                <>
                  <span className="overlay-text bg-yellow gray-dark">Paquetes</span>

                </>
              )}

              {item.id_type_of_shops == "10" && (
                <>
                  <span className="overlay-text bg-yellow gray-dark">Recargas</span>

                </>
              )}

              {item.id_type_of_shops == "11" && (
                <>
                  <span className="overlay-text bg-yellow gray-dark">Apuestas</span>

                </>
              )}

              {item.discount !== "" && (
                <>
                  <span className="overlay-promo-text color-white">{item.discount}</span>

                </>
              )}

            </div>
            <span className="inter_14_600 gray-dark mt-2 px-2 w-100 left rm-2" style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {item.title}
            </span>
            <span className="inter_12 gray-dark  px-2  w-100 left rm-5" style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.subtitle}</span>
            <span className="inter_14_600 gray-dark px-2 w-100 left rm-2">

              {numeral(item.valor_de_venta).format('0') !== "0" ? (
                <div>{numeral(item.valor_de_venta).format('$0,0')}  pesos</div>
              ) : (
                <div style={{ color: 'white' }}>.</div>
              )}
            </span>
            <span className="inter_14_600_green   w-100 left  rm-2">Hasta {item._return_to_presentation_users}% Cashback</span>
          </div>
          )
        })}
      </div>
    </div>
  );
};

export default ItemProductRefacil;
