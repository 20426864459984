import React from 'react';

import { useNavigate } from 'react-router-dom';
import HeaderTitle from '../layouts/HeaderTitle';
import TransactionCard from '../layouts/TransactionCard';
import ColorPages from '../layouts/Colorpages';

const Verhistorial = () => {


  return (
    <div>
      <ColorPages />
      <HeaderTitle title="Historial de puntos por confirmar" />
      <div className='page px-4'>
        <TransactionCard />
      </div>
    </div>
  );
};

export default Verhistorial;
