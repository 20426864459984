import React, { useState, useRef } from 'react';


const ScrollContainer = ({ children, onDragChange}) => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  
  

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
    onDragChange(true)
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
    onDragChange(false)
  };

  const handleMouseUp = () => {
    const endScrollLeft = containerRef.current.scrollLeft;
    if (endScrollLeft !== scrollLeft) {
      setTimeout(() => {
        setIsDragging(false)
        onDragChange(false)
      }
        , 0.3);

    } else {
      setIsDragging(false);
      onDragChange(false);
    }
  }
  
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1.3;  
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  

  return (
    <div
     className='container-inline'
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      
        
      
    >
      {children}
    </div>
  );
};

export default ScrollContainer;
