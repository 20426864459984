import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SplashScreen from './components/SplashScreen';
import Register from './components/Session/Register';
import Terminosycondiciones from './components/Session/Register/Terminosycondiciones';
import Login from './components/Session/Login';
import Home from './components/Home';
import Menu from './components/Home/Menu';
import RedimirPuntos from './components/Home/RedimirPuntos';
import Perfil from './components/Home/Perfil';
import DatosBancarios from './components/Home/DatosBancarios';
import Ayuda from './components/Home/Ayuda';
import HistorialCompras from './components/Home/HistorialCompras';
import InvitarAmigo from './components/Home/InvitarAmigo';
import EcommerceComercio from './components/Home/EcommerceComercio';
import Misintereses from './components/Home/Misintereses';
import Membresia from './components/Home/Membresia';
import Solicitarpuntos from './components/Home/Solicitarpuntos';
import Endcanjear from './components/Home/Endcanjear';
import Verhistorial from './components/Home/Verhistorial';
import Bono from './components/Home/Bono';
import Endsolicitudcuenta from './components/Home/Endsolicitudcuenta';
import Endbeneficios from './components/Home/Endbeneficios';
import Ubicaciones from './components/Home/Ubicaciones';
import BonosComercio from './components/Home/BonosComercio';
import EcommerceProducto from './components/Home/EcommerceProducto';
import ExternalAliados from './components/Home/ExternalAliados';
import UbicacionesExternal from './components/Home/UbicacionesExternal';
import ProductoExternal from './components/Home/ProductoExternal';
import TiendaComercio from './components/Home/TiendaComercio';
import TiendaProductoFisico from './components/Home/TiendaProductoFisico';
import EndTienda from './components/Home/EndTienda';
import Cart from './components/Home/Cart';
import ScrollToTop from './components/layouts/ScrollToTop';

import './App.css';
import './styles-desktops.css';
import './rowsystem.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Recargas from './components/Home/Refacil.js';
import RefacilItem from './components/Home/RefacilItem.js';
import ConfirmarPago from './components/Home/ConfirmarPago.js';
import PagoConfirmado from './components/Home/PagoConfirmado.js';
import PagoRegalo from './components/Home/PagoRegalo.js';
import MetodosDePago from './components/Home/MetodosDePago.js';
import AgregarMetodo from './components/Home/AgregarMetodo.js';
import AgregarTarjeta from './components/Home/AgregarTarjeta.js';
import DetalleMetodo from './components/Home/DetalleMetodo.js';
import MiBilletera from './components/Home/MiBilletera.js';
import BonosDestEsp from './components/Home/BonosDestEsp.js';
import Category from './components/Home/Category.js';
import MisBonos from './components/Home/MisBonos.js';
import Suscripciones from './components/Home/Suscripciones.js';
import Subcategory from './components/Home/Subcategory.js';
import NotificacionDecomunicacion from './components/Home/NotificacionDecomunicacion.js';
import Didiproducto from './components/Home/Didiproducto.js';
import HistorialPuntos from './components/Home/HistorialPuntos.js';
import Refacilcart from './components/Home/Refacilcart.js';
import Psepayment from './components/Home/PsePayment.js';
import NewOpenPseUrlActivity from './components/Home/NewOpenPseUrlActivity.js';
import MisInvitados from './components/Home/MisInvitados.js';
import Mensajes from './components/Home/Mensajes.js';
import CentroNotificaciones from './components/Home/CentroNotificaciones.js';


const App = () => {
  return (
    <div className="App">
           <ToastContainer position="top-right" autoClose={100} hideProgressBar newestOnTop closeOnClick pauseOnHover />

      <Router>
      <ScrollToTop />
        <Routes>
        <Route path="/" exact element=<SplashScreen/> />
        <Route path="/home/Category" exact  element=<Category/> /> 
        <Route path="/home/Subcategory" exact  element=<Subcategory/> /> 
          <Route path="/Session/Register" exact  element=<Register/> />
          <Route path="/Session/Register/Terminosycondiciones" exact  element=<Terminosycondiciones/> />
          <Route path="/Session/Login" exact element=<Login/> />
          <Route path="/home" exact  element=<Home/> />
          <Route path="/home/menu" exact  element=<Menu/> />
          <Route path="/home/Perfil" exact  element=<Perfil/> />    
          <Route path="/home/RedimirPuntos" exact  element=<RedimirPuntos/> />
          <Route path="/home/DatosBancarios" exact  element=<DatosBancarios/> />    
          <Route path="/home/Ayuda" exact  element=<Ayuda/> />         
          <Route path="/home/HistorialCompras" exact  element=<HistorialCompras/> />    
          <Route path="/home/HistorialPuntos" exact  element=<HistorialPuntos/> />    
          
          <Route path="/home/InvitarAmigo" exact  element=<InvitarAmigo/> />     
 
   
          <Route path="/home/EcommerceComercio" exact  element=<EcommerceComercio/> />   
          <Route path="/home/Misintereses" exact  element=<Misintereses/> />  
          <Route path="/home/Membresia" exact  element=<Membresia/> />  
          <Route path="/home/Solicitarpuntos" exact  element=<Solicitarpuntos/> />  
          <Route path="/home/Endcanjear" exact  element=<Endcanjear/> />  
          <Route path="/home/Verhistorial" exact  element=<Verhistorial/> />  
          <Route path="/home/Bono" exact  element=<Bono/> />  
          <Route path="/home/Endsolicitudcuenta" exact  element=<Endsolicitudcuenta/> />  
          <Route path="/home/Endbeneficios" exact  element=<Endbeneficios/> />
          <Route path="/home/Ubicaciones" exact  element=<Ubicaciones/> />     
          <Route path="/home/BonosComercio" exact  element=<BonosComercio/> />     
          <Route path="/home/EcommerceProducto" exact  element=<EcommerceProducto/> />    
     
          <Route path="/home/Refacil" exact  element=<Recargas/> /> 
          <Route path="/home/RefacilItem" exact  element=<RefacilItem/> /> 
     
          <Route path="/home/ExternalAliados" exact  element=<ExternalAliados/> />   
          <Route path="/home/UbicacionesExternal" exact  element=<UbicacionesExternal/> />   
          <Route path="/home/ProductoExternal" exact  element=<ProductoExternal/> />  
  

          <Route path="/home/TiendaComercio" exact  element=<TiendaComercio/> />  
          <Route path="/home/TiendaProductoFisico" exact  element=<TiendaProductoFisico/> /> 
          <Route path="/home/EndTienda" exact  element=<EndTienda/> />  
          <Route path="/home/Cart" exact  element=<Cart/> /> 
          <Route path="/home/Refacilcart" exact  element=<Refacilcart/> /> 
          <Route path="/home/NewOpenPseUrlActivity" exact  element=<NewOpenPseUrlActivity/> /> 
          
           
          <Route path="/home/ConfirmarPago" exact  element=<ConfirmarPago/> />    
          <Route path="/home/Psepayment" exact  element=<Psepayment/> />    
                 
          <Route path="/home/PagoConfirmado" exact  element=<PagoConfirmado/> />           
          <Route path="/home/PagoRegalo" exact  element=<PagoRegalo/> />           
          <Route path="/home/MetodosDePago" exact  element=<MetodosDePago/> />           
          <Route path="/home/AgregarMetodo" exact  element=<AgregarMetodo/> />           
          <Route path="/home/AgregarTarjeta" exact  element=<AgregarTarjeta/> />           
          <Route path="/home/DetalleMetodo" exact  element=<DetalleMetodo/> />           
          <Route path="/home/MiBilletera" exact  element=<MiBilletera/> />           
          <Route path="/home/BonosDestEsp" exact  element=<BonosDestEsp/> />           
          <Route path="/home/MisBonos" exact  element=<MisBonos/> />           
          <Route path="/home/Suscripciones" exact  element=<Suscripciones/> /> 
          <Route path="/home/NotificacionDecomunicacion" exact  element=<NotificacionDecomunicacion/> />          
          <Route path="/home/Didiproducto" exact  element=<Didiproducto/> /> 
          <Route path="/home/MisInvitados" exact  element=<MisInvitados/> />  
          <Route path="/home/Mensajes" exact  element=<Mensajes/> />
          <Route path="/home/CentroNotificaciones" exact  element=<CentroNotificaciones/> />
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
