import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ItemComerciosBonos = ({title, data}) => {

  const randomNumber = Math.floor(Math.random() * 1000);
  let navigate = useNavigate();
  const handleClick = (code,id_type_of_shops) => {


    if ([6, 1, 4, 5].includes(id_type_of_shops)) {
      navigate("/home/EcommerceProducto");
    }

    if (id_type_of_shops === 3) {  
      navigate(`/home/BonosComercio?code=${code}&rand=${randomNumber}`);
      window.location.reload();
    }

    if (id_type_of_shops === 2) {
    }

  };

  return (
    <div className="container  pt-0 pb-0">
      <div className="inter_14_gray left ml-2">{title}</div>
      <div className="container-inline mt-2 w-100">
        {data.map((item) => (
          <div className=" percentagecomercios m-2" key={item.id_items}  onClick={() => handleClick(item.id_items, item.id_type_of_shops)}>
       <div className="card-rounded center" style={{ backgroundColor: item.background_color }}>
              <div className="image-container m-2">
                <img src={item.image} className="centered-image" alt="Imagen" />
              </div>
            </div>
            <div className='row  center' style={{ marginTop: "19px" }}> <img src={process.env.PUBLIC_URL + '/images/discount.png'} className='img_discount' alt="splash" />
              <div className="inter_12_700 color-green pl-1">Hasta {item._return_to_presentation_users}%</div></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemComerciosBonos;
