import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login, loginfire } from '../../../apiService';
import { initializeApp } from "firebase/app"
import { signInWithCredential, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, getAuth, getRedirectResult, signInWithRedirect } from "firebase/auth";
import isMobile from 'is-mobile';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';


const Login = () => {
    const destroy = () => {
        console.log('Función destroy dummy ejecutada');
      };

    const firebaseConfig = {
        apiKey: "AIzaSyDOQzXakac4NBzXG2D0POgSvmHivNTXOJw",
        authDomain: "fleksi-24f8f.firebaseapp.com",
        projectId: "fleksi-24f8f",
        storageBucket: "fleksi-24f8f.appspot.com",
        messagingSenderId: "356390905973",
        appId: "1:356390905973:web:7f1a0d65183a09e004703c",
        measurementId: "G-LMT6PYZ553"
    };

    // Initialize Firebase and Firebase Authentication
    const app = initializeApp(firebaseConfig)
    const auth = getAuth(app)


    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailFocused, setEmailFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const goRegister = () => {
        navigate("/Session/Register");
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleInputFocus = (event) => {
        const { name } = event.target;

        if (name === 'email') {
            setEmailFocused(true);
        } else if (name === 'password') {
            setPasswordFocused(true);
        }
    };

    const handleInputBlur = (event) => {
        const { name, value } = event.target;

        if (name === 'email') {
            setEmailError(value.trim() === '' ? 'Este campo es obligatorio' : '');
            setEmailFocused(false);
            if (value !== '') {
                setEmailFocused(true);
            }
        }
        else if (name === 'password') {
            setPasswordError(value.trim() === '' ? 'Este campo es obligatorio' : '');
            setPasswordFocused(false);
            if (value !== '') {
                setPasswordFocused(true);
            }

        }
    };


    const handleLogin = async () => {

        // Validación del campo de email
        if (email.trim() === '') {
            setEmailError('Este campo es obligatorio');
            return;
        } else {
            setEmailError('');
        }

        // Validación del formato de email
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
            setEmailError('Ingrese un email válido');
            return;
        } else {
            setEmailError('');
        }

        // Validación del campo de contraseña
        if (password.trim() === '') {
            setPasswordError('Este campo es obligatorio');
            return;
        } else {
            setPasswordError('');
        }

        try {
            const tokenobtenido = (token) => {

                localStorage.setItem('accessToken', token);
                navigate("/home");
            };

            const errorcall = (token) => {
                setPasswordError('La contraseña es incorrecta. Inténtalo de nuevo.')
            };
            login(email, password, tokenobtenido, errorcall);

            // Aquí puedes hacer algo con el access_token, como guardarlo en el estado o en localStorage
        } catch (error) {
            console.error(error);
        }
    };

    // Observar cambios en el estado de autenticación
   onAuthStateChanged(auth, (user) => {
        if (user) {
            user.getIdToken().then((idToken) => {
                const tokenobtenido = (token) => {
                    if (token != "") {
                        localStorage.setItem('accessToken', token);
                        navigate('/home');
                    } else {
                        
                        navigate('/Session/Register');
                    }
                };
                loginfire({ Firebasetoken: idToken }, tokenobtenido);
            });
        } else {
            // El usuario no ha iniciado sesión
        }
    });

    getRedirectResult(auth)
        .then((result) => {
            // The signed-in user info.
            const user = result.user;
            if (user) {
                user.getIdToken().then((idToken) => {
                    const tokenobtenido = (token) => {
                        if (token != "") {
                            localStorage.setItem('accessToken', token);
                            navigate('/home');
                        } else {
                            navigate('/Session/Register');
                        }

                    };
                    loginfire({ Firebasetoken: idToken }, tokenobtenido);
                });
            } else {
                // El usuario no ha iniciado sesión
            }
        }).catch((error) => {
        });

   const responseMessage = (response) => {
        // Build Firebase credential with the Google ID token.
        const idToken = response.credential;
        const credential = GoogleAuthProvider.credential(idToken);

        // Sign in with credential from the Google user.
        signInWithCredential(auth, credential).catch((error) => {
        });
    };


    const errorMessage = (error) => {
        console.log(error);
    };


    return (
        <div className="bg_size bg-white">
            <div className="medium-40-half">
                <div className="center-content">
                    <label className='title-bold w-100   font-40'>¡HOLA!</label>
                    <label className='subtitle font-18 m-20 w-100' >Bienvenido</label>
                </div>
            </div>

            <div >
            <form className="card-form mb-4">
                    <div className="center-content w-100 mb-3">
                        <div className={`w-75 form-input-container ${emailFocused ? 'input-filled' : ''}`}>
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                className="form-input"
                            />
                            {emailError && <span className="error-label">{emailError}</span>}
                        </div>
                        <div className={`w-75 form-input-container ${passwordFocused ? 'input-filled' : ''}`}>
                            <label className="form-label">Contraseña</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                          
                                name="password"
                                value={password}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                className="form-input"
                            />
                                 <span className="password-toggle" onClick={togglePasswordVisibility}>
                                <img style={{ width: "25px" }}
                                    src={showPassword ? process.env.PUBLIC_URL + '/images/showpassword.png' : process.env.PUBLIC_URL + '/images/hidepassword.png'}
                                    alt={showPassword ? 'Show Password' : 'Hide Password'}
                                />
                            </span>
                            {passwordError && <span className="error-label">{passwordError}</span>}
                        </div>

                        <div className='inter_12'>¿Has olvidado tu contraseña?</div>

                        <div className='inter_12_500' style={{ marginTop: '33px' }}>Si todavía no tienes una cuenta <span onClick={goRegister} className='registrate_line'>
                            registrate aquí?</span></div>


                        <div className='row col-12' style={{ marginTop: "35px" }}>

                            <div className='col  m-0 p-0 right'><img className="w-75" src={process.env.PUBLIC_URL + '/images/linerect.png'} alt="Imagen" /> </div>
                            <div className='col-1 pt-1'><img style={{ height: "12px", width: "12px" }} src={process.env.PUBLIC_URL + '/images/ellipse.png'} alt="Imagen" /> </div>
                            <div className='col  m-0 p-0 left'><img className="w-75" src={process.env.PUBLIC_URL + '/images/linerect.png'} alt="Imagen" /> </div>
                        </div>
                        <button style={{ marginTop: "35px" }} type="button" onClick={handleLogin} className="button-primary">Inicia sesión</button>
                        <div style={{ marginTop: "17px", marginBottom: "17px" }} >

                        <GoogleLogin text="continue_with" width='291px' onSuccess={responseMessage} onError={errorMessage} />
                        </div>

                    </div>
                
            </form>
                </div>

        </div>
    );
}

export default Login;
