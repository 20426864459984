import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderTitle from "../layouts/HeaderTitle";
import TabLayout from "../layouts/TabLayout";
import NotificationModal from "../layouts/NotificationModal";
import { postarray, postsingle } from "../../apiService";
import numeral from "numeral";
import ImageComponent from "../layouts/ImageComponent";
import ItemProduct from "../layouts/ItemProduct";
import useCart from "../utils/useCart";
import TabLayoutLocation from "../layouts/TabLayoutLocation";
import ColorPages from "../layouts/Colorpages";
import ValueReload from "../layouts/ValueReload";
import useCartRefacil from "../utils/useCartrefacil";

const RefacilItem = () => {
  const [bono, setBono] = useState({});
  const [related_products, setRelated_products] = useState([]);
  const [related_productstwo, setRelated_productstwo] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const type = queryParams.get("type");

  
  const fetchData = async () => {
    setTotalcart(total_cart());
    try {
      const bonoReponse = await postarray(
        { id_fleksi_products: code },
        "RefacilItem"
      );
      setBono(bonoReponse);
      setRelated_products(bonoReponse.related_products.productsone);
      setRelated_productstwo(bonoReponse.related_products.productstwo);
    } catch (error) {
      console.error(error);
    }
    return () => {};return () => {}

  };

  useEffect(() => { 
    fetchData();
  }, []);

  const navigate = useNavigate();
  const [valor, setValor] = useState(1);
  const [totalcart, setTotalcart] = useState(0);
  const [activeTabs, setActiveTabs] = useState([0, 4]);
  const [showNotification, setShowNotification] = useState(false);
  const { addToCart, total_cart } = useCartRefacil();

  const gocart = () => {
    addToCart({
      id_items: bono.iden_product,
      actual_code_product: bono.iden_product,
      title: bono.title,
      quantity: valor,
      price: bono.valor_de_venta,
      total_price: bono.valor_de_venta * valor,
      image: bono.image,
    });
    setTotalcart(total_cart());

    navigate("/home/Refacilcart");
  };


  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleTabClick = (index) => {
    if (activeTabs.includes(index)) {
      setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index));
    } else {
      setActiveTabs([...activeTabs, index]);
    }
  };

  const incrementarValor = () => {
    setValor(valor + 1);
  };

  const decrementarValor = () => {
    if (valor > 1) {
      setValor(valor - 1);
    }
  };

  return (
    <div>
      <ColorPages />
      <HeaderTitle title={bono.ecommerce} totalcart={totalcart} />
      <div className="page px-4">
        <div className="mt-2 center" style={{ height: "250px" }}>
          <ImageComponent
            src={bono.image}
            alt={bono.title}
            defaultSrc={process.env.PUBLIC_URL + "/images/defaultimage.png"}
            cssclass={"img-bono"}
          />
        </div>
        <div className="mt-2 col-12 row mt-4 ">
          <div className="col">
            <p className="inter_18_600 left gray-dark">{bono.title}</p>
            {type === 9 && bono && bono.meta && bono.meta.validity && (
              <p className="inter_12 left gray-dark">{bono.meta.validity}</p>
            )}
          </div>
          <div className="row col-12">
            {bono &&
              bono.meta &&
              bono.meta.plus &&
              bono.meta.plus.map((item, index) => (
                <div className="col text-center">
                  <i
                    key={index}
                    className={`bi bi-${item} color-icons-refacil`}
                  />
                </div>
              ))}
          </div>
          <div className="col-2"></div>
        </div>

        <div className="row m-0  mt-3">
          <div>
            <p className="inter_18_600 left gray-dark">
              {numeral(bono.valor_de_venta).format("$0,0")}{" "}
            </p>
          </div>
          <div className="col d-inline left mt-1">
            <img
              src={process.env.PUBLIC_URL + "/images/discount.png"}
              style={{ width: "20px", height: "14px" }}
            />
            <div className="inter_14_700 left  color-green ml-1">
              Hasta {bono._return_to_presentation_users}% Cashback
            </div>
          </div>
        </div>

        <div className="row m-0 " style={{ marginTop: "21px" }}>
          <div className="bono-redondo">
            <div className="botones center">
              <button className="boton-menos" onClick={decrementarValor}>
                {" "}
                -{" "}
              </button>
              <div className="inter_14_400_normal gray-dark">{valor}</div>
              <button className="boton-mas" onClick={incrementarValor}>
                {" "}
                +{" "}
              </button>
            </div>
          </div>
          {type == 10 && (
              <ValueReload /> 
            )}
          
        </div>
        {showNotification && (
          <NotificationModal
            message="Agregado al carrito."
            onClose={handleCloseNotification}
          />
        )}
        <div className="center mt-4  row col-12  px-4 ">
      
          <div className="col m-0 p-0 center">
            <button
              type="submit"
              onClick={gocart}
              className="button-primary w-100 ml-2"
            >
              Ir a pagar
            </button>
          </div>
        </div>

        <div className="tab-layout">
          <div className="tab-navigation p-0 m-0 ">
            <ul>
              {bono.subtitle && (
                <TabLayout
                  key={0}
                  title="Descripción"
                  description={bono.subtitle}
                  active={activeTabs.includes(0)}
                  handleTabClick={() => handleTabClick(0)}
                />
              )}
              {bono.description && (
                <TabLayout
                  key={1}
                  title="Comercio"
                  description={bono.description}
                  active={activeTabs.includes(1)}
                  handleTabClick={() => handleTabClick(1)}
                />
              )}

              {bono.terms_and_conditions && (
                <TabLayout
                  key={4}
                  title="Términos y condiciones"
                  description={bono.terms_and_conditions}
                  active={activeTabs.includes(3)}
                  handleTabClick={() => handleTabClick(3)}
                />
              )}
            </ul>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "32px" }}>
        <ItemProduct
          title={"Productos que te podrían gustar"}
          data={related_products}
        />
        <ItemProduct title={""} data={related_productstwo} />
      </div>
    </div>
  );
};
export default RefacilItem;
