import React, {  useState } from "react";
import Headerback from "../layouts/Headerback";
import { useNavigate } from "react-router-dom";
import ColorPages from "../layouts/Colorpages";
const AgregarTarjeta = () => {
  const [apellidoFocused, setApellidoFocused] = useState(false);
  const [nombreFocused, setnombreFocused] = useState(false);
  const [numeroFocused, setnumeroFocused] = useState(false);
  const [FechaExpFocused, setFechaExpFocused] = useState(false);
  const [CVVFocused, setCVVFocused] = useState(false);
  const navigate = useNavigate();
  const hadleGoDetalleMetodo = () => {
    navigate("/home/DetalleMetodo");
}
  const handleInputFocus = (event) => {
    const { name } = event.target;

    if (name === "nombre") {
      setnombreFocused(true);
    } else if (name === "apellido") {
      setApellidoFocused(true);
    } else if (name === "fechaExpiracion") {
      setFechaExpFocused(true);
    } else if (name === "numero") {
      setnumeroFocused(true);
    } else if (name === "cvv") {
      setCVVFocused(true);
    }
  };

  return (
    <div className="container">
      <div>
        <ColorPages></ColorPages>
        <Headerback title="" />
      </div>
      <div className="row">
        <div className="col mt-5 inter_20_normal left ml-2 ">
          <span> Agregar tarjeta </span>
        </div>
      </div>
      <div className="card_main mt-4 p-0 ">
        <div className="row p-0 m-0">
          <div className="col-8 pl-4  left">
            <img
              src="https://img.icons8.com/fluency/48/sim-card-chip.png"
              alt="sim-card-chip"
              className=""
              style={{ width: "48px", height: "auto", marginTop: "40px" }}
            />
          </div>
          <div className="col p-0 m-0" style={{ marginTop: "38px" }}>
            <div className="inter_16_800 w-100"></div>
            <div className="inter_12 color-white"></div>
          </div>
        </div>

        <div className="row p-0 m-0">
          <div className="col-12 pl-4 pt-3 left inter_16_800">
            xxxx xxxx xxxx xxxx
          </div>
          <div className="col-6 inter_16_800 mt-4 ml-4   ">
            {" "}
            NOMBRE Y APELLIDO
          </div>
          <div className="col-4 inter_16_800 mt-4 right mb-4 ">MM/YY</div>
        </div>
      </div>
      <div className="mt-4">
        <form>
          <div>
            <div
              className={`form-input-container ${
                numeroFocused ? "input-filled" : ""
              }`}
            >
              <div className=" row">
              <div className=" form-input-container col">
                <label className="form-label ml-3">Numero de la tarjeta</label>
                
                  <input
                    type="text"
                    name="numero"
                    onFocus={handleInputFocus}
                    className="form-input"
                  />
                 
                 
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/ios/50/camera--v4.png"
                    alt="camera--v4"
                    style={{ position: "absolute",top: "50%", right: "10px",transform: "translateY(-50%)" }}
                  />
                  
                
              </div>
              </div>
            </div>
            <div className="row">
              <div
                className={`form-input-container col  ${
                  nombreFocused ? "input-filled" : ""
                }`}
              >
                <div className=" form-input-container">
                  <label className="form-label">Nombre </label>
                  <input
                    type="text"
                    name="nombre"
                    onFocus={handleInputFocus}
                    className="form-input"
                  />
                </div>
              </div>
              <div
                className={` form-input-container col ${
                  apellidoFocused ? "input-filled" : ""
                }`}
              >
                <label className="form-label ml-2">Apellido</label>
                <input
                  type="text"
                  name="apellido"
                  onFocus={handleInputFocus}
                  className="form-input"
                />
              </div>
            </div>

            <br />
            <div className="row">
              <div
                className={`form-input-container col  ${
                  FechaExpFocused ? "input-filled" : ""
                }`}
              >
                <div className=" form-input-container">
                  <label className="form-label">Fecha de expiración</label>

                  <input
                    type="text"
                    name="fechaExpiracion"
                    onFocus={handleInputFocus}
                    className="form-input"
                  />
                  <img
                    width="25"
                    height="25"
                    src="https://img.icons8.com/windows/32/info.png"
                    alt="info"
                    style={{ position: "absolute",top: "55%", left: "150px",  transform: "translateY(-50%)" }}
                  />
                </div>
              </div>
              <div
                className={` form-input-container col ${
                  CVVFocused ? "input-filled" : ""
                }`}
              >
                <label className="form-label ml-2">CVV</label>

                <input
                  type="text"
                  name="cvv"
                  onFocus={handleInputFocus}
                  className="form-input"
                />
                <img
                  width="25"
                  height="25"
                  src="https://img.icons8.com/windows/32/info.png"
                  alt="info"
                  style={{ position: "absolute", top: "35%", right: "10px",  transform: "translateY(-50%)" }}
                />
              </div>
            </div>
          </div>
          <div>
            <button onClick={hadleGoDetalleMetodo} className="button-primary w-100 ">Agregar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgregarTarjeta;
