import React from 'react';
import { useNavigate } from 'react-router-dom';

const Cupones = ({ title, data }) => {
  const navigate = useNavigate();
  const handleCardClick = (id) => {
    navigate("/home/Bono");
  };

  return (
    <div className="container ">
      <div className="inter_14_700 gray-dart left ml-2">{title}</div>

      <div className="row px-3">
        {data.map((item) => (
          <div
            className="bonos mt-3"
            style={{ backgroundColor: item.background }}
            key={item.id}
            onClick={() => handleCardClick(item.id)}
          >
            <div className="center">
              <div className="bonos-image">
                <img src={item.image} alt="Imagen" />
              </div>
            </div>
            <div className="inter_12_700 color-green mt-2 px-2 w-100 left">{item.name}</div>
            <div className="inter_10_400 color-green pl-2 left w-100">{item.cash}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cupones;
