import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Headerback from "../layouts/Headerback";
import ItemProductBono from "../layouts/ItemProductBono";
import CardComponentVerticalsmall from "../layouts/CardComponentVerticalsmall";
import TabLayout from "../layouts/TabLayout";
import ItemProductEcommerce from "../layouts/ItemProductEcommerce";
import { getdata, post, postarray } from "../../apiService";
import ImageComponent from "../layouts/ImageComponent";
import numeral from "numeral";
import ColorPages from "../layouts/Colorpages";

const EcommerceProducto = () => {
  const navigate = useNavigate();

  const [product, setProduct] = useState({});
  const [related_products, setRelated_products] = useState([]);
  const [related_productstwo, setRelated_productstwo] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");

  const fetchData = async () => {
    try {
      const productResponse = await postarray(
        { id_fleksi_products: code },
        "productoecommerce"
      );
      setProduct(productResponse);
      setRelated_products(productResponse.related_products.productsone);
      setRelated_productstwo(productResponse.related_products.productstwo);
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);

  const goEcommerce = (link, code) => {
    const fetchData = async () => {
      try {
        await postarray({ id_items: code }, "save_buy_intention");
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    window.open(link, "_blank");
  };

  const [activeTabs, setActiveTabs] = useState([0]);

  const handleTabClick = (index) => {
    if (activeTabs.includes(index)) {
      setActiveTabs(activeTabs.filter((tabIndex) => tabIndex !== index));
    } else {
      setActiveTabs([...activeTabs, index]);
    }
  };

  return (
    <div>
      <ColorPages />
      <Headerback title={product.ecommerce} />
      <div className="page ">
        <div className="bono-container">
         
            <div className="mt-2 center">
              <ImageComponent
                src={product.image}
                alt={product.title}
                defaultSrc={process.env.PUBLIC_URL + "/images/defaultimage.png"}
                cssclass={"img-bono"}
              />
            </div>
          
          

          <div className="px-4">
            <div className="mt-2 col-12 row ">
              <div className="col">
                <p className="inter_18_600 left gray-dark">{product.title}Teatro Nacional</p>
              </div>
            </div>
            <div className=" col-12 mt-2">
              <p className="inter_10_400 color-gray-light left">
                {product.subtitle}
              </p>
            </div>

            <div className="row m-0  mt-3">
              <div className="col-4 ">
                <p className="inter_18_600 left gray-dark">
                  {numeral(product.valor_de_venta).format("$0,0")}
                </p>
              </div>
              <div className="col d-inline center p-0 m-0">
                <img
                  src={process.env.PUBLIC_URL + "/images/discount.png"}
                  style={{ width: "20px", height: "14px" }}
                />
                <div className="inter_14_700 left  color-green ml-1">
                  Hasta {product._return_to_presentation_users}% Cashback
                </div>
              </div>
            </div>

            <div className="center mt-3">
              <button
                type="button"
                onClick={() => goEcommerce(product.link, product.id_items)}
                className="button-primary"
              >
                Comprar ahora
              </button>
            </div>
          </div>

          <div className="tab-layout ">
            <div className="tab-navigation">
              <ul>
                {product.benefits && (
                  <TabLayout
                    key={3}
                    title="Beneficios"
                    description={product.benefits}
                    active={activeTabs.includes(0)}
                    handleTabClick={() => handleTabClick(0)}
                  />
                )}
                {product.terms_and_conditions && (
                  <TabLayout
                    key={4}
                    title="Términos y condiciones"
                    description={product.terms_and_conditions}
                    active={activeTabs.includes(1)}
                    handleTabClick={() => handleTabClick(1)}
                  />
                )}
              </ul>
            </div>
          </div>

          <div style={{ marginTop: "32px" }}>
            <ItemProductEcommerce
              title={"Productos que te podrían gustar"}
              data={related_products}
            />
            <ItemProductEcommerce title={""} data={related_productstwo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcommerceProducto;
