import React from 'react';
import { useNavigate } from 'react-router-dom';
import ColorPages from '../layouts/Colorpages';

const Endsolicitudcuenta = () => {
  const navigate = useNavigate();



  const end = () => {
    navigate("/home");
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className='App backend' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <ColorPages />
      <div className='w-100 backend pt-3' style={{ position: 'fixed', top: 0, zIndex: 999 }}>
        <div className="banner">
          <div className="left-div" onClick={handleGoBack}>
            <img
              src={process.env.PUBLIC_URL + '/images/img-back-white.png'}
              alt="Slider 1"
              style={{ width: '28px', height: 'auto' }}
            />
          </div>
          <div className="center-div align-items-center">
            <div className="header-title left w-100"></div>
          </div>
          <div className="right-div"></div>
        </div>
      </div>
      <div className='mt-3 center d-block'>
        <p className='w-100  color-white inter_18 pb-3'>Hemos recibido tu solicitud</p>
        <p className='w-100  color-white inter_12 pb-3'>TÚ CUENTA HA SIDO
          CAMBIADA CON ÉXITO</p>
        <button onClick={end} className='button-secundary backend  button-text'>Continuar</button>
      </div>
    </div>
  );
};

export default Endsolicitudcuenta;
