import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const CategorySectionRefacil = ({ data, code, clickload }) => {

  const handleClick = (code) => {
    clickload(code);
  };

  return (
    <div className="section-category mt-4">
      <div className="inter_14_gray left ml-4">Categorías</div>
      <div className="container-inline w-100 mt-2 ">
        {data.map((item) => (
          <div className="col-3" key={item.category} onClick={() => handleClick(item.category)}>
            <div className={`card_gray center ${item.category == code ? 'bac_primary_no_color' : ''}`}>

              <div className="image-container">
                <img
                  src={process.env.PUBLIC_URL + item.image}
                  className="centered-image "
                  alt="Imagen"
                />
              </div>
            </div>
            <span className="inter_12 gray mt-2">{item.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategorySectionRefacil;
