import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const ItemComerciosEcommerce = ({title, data}) => {
  let navigate = useNavigate();
  const handleImageClick = (code, id_type_of_shops,link) => {
    const randomNumber = Math.floor(Math.random() * 1000);
    navigate(`/home/EcommerceComercio?code=${link}&rand=${randomNumber}`);
  };
  return (
    <div className="container mt-0 mb-0  pt-0 pb-0 ">
      <div className="inter_14 gray-dark left ml-3">{title}</div>
      <div className="container-inline   w-100">
        {data.map((item) => (
          <div className=" comercios-products  col-3 percentagecomercios mx-2 mt-3 " key={item.id_items} onClick={() => handleImageClick(item.id_items, item.id_type_of_shops,item.link)}>
            <div className="card-rounded center" style={{ backgroundColor: item.background_color }}>
              <div className="image-container m-2">
                <img src={item.image} className="centered-image" alt="Imagen" />
              </div>
            </div>
            <div className='row  center' style={{ marginTop: "14px" }}> <img src={process.env.PUBLIC_URL + '/images/discount.png'} className='img_discount' alt="splash" />
              <div className="inter_12_700 color-green pl-1">Hasta {item._return_to_presentation_users}%</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemComerciosEcommerce;
