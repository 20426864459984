import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import numeral from 'numeral';

const ItemHistorialCompras = ({ transactions }) => {
  const navigate = useNavigate();
  const handleItemClick = (transactionId) => {
    // Realiza la acción deseada al hacer clic en el elemento
    //navigate(`/transaccion/${transactionId}`);
    //segun lo que compro o lo que hizo  navigate("/home/HistoricoProductofisico")
    //  <Link to="../Home/Perfil" className="link-no-underline button-primary p-0 right"  style={{ width:"140px", height:"30px" }}>Ver detalle</Link>
         
  };


  return (
    <>
      {transactions.map(transaction => (
        <div>
        <div className='row col-12 mx-2' key={transaction.id}
         onClick={() => handleItemClick(transaction.id)}>
     <div className='row col-12  ml-3  transaction-info mr-4'>{transaction.status}</div>
     <div className='row col-12 left ml-3  mr-4 mt-2 transaction-info'  style={{ fontSize:"12px" }}>{transaction.statusdescription}</div>
         
          <div className='row col-12 ml-3  mr-4 mt-2'>
            <div className='col left p-0'>ID transación </div>
            <div className='col right'><span className='transaction-info' style={{ fontSize:"12px" }}>{transaction.x_ref_payco}</span> </div>

            <div className='row col-12 mt-2'>
              <div className='col left'><span>Fecha</span> </div>
              <div className='col right  p-0'><span className='transaction-info' style={{ fontSize:"12px" }}>{transaction.fecha_compra}</span> </div>
            </div>

            <div className='row col-12 mt-2'>
              <div className='col left'><span>Total pagado</span> </div>
              <div className='col right p-0'><span className='transaction-info' style={{ fontSize:"12px" }}>
              { numeral(numeral(transaction.totalPse).value() + numeral(transaction.totalCard).value() + numeral(transaction.rewards_usados).value()).format('$0,0')}
                
                </span> </div>
            </div>
            <div className='row col-12 w-100 right'>
              <div className='col'></div>
              <div  className='col-3 right'>
             </div>
            </div>
          </div>
   
        </div>
        <div className='w-100 mt-4 mb-4'  style={{ height:"1px" , backgroundColor:"gray" }}></div>
        </div>
      ))}
    </>
  );
};

export default ItemHistorialCompras;
