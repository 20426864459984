import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>¡Descarga nuestra app ahora!</h1>
      <p>Para una experiencia más rápida y cómoda, te invitamos a descargar nuestra aplicación móvil.</p>
      <p>Disponible en:</p>

      <div className="download-links">
        <a href="https://play.google.com/store/apps/details?id=com.fleksirewards&hl=es-CO" target="_blank" rel="noopener noreferrer">
          <img src="https://fleksirewards.com/google-play.png" alt="Descargar en Google Play" />
        </a>
        <a href="https://apps.apple.com/us/app/fleksi/id1623507497" target="_blank" rel="noopener noreferrer">
          <img src="https://fleksirewards.com/app-store.png" alt="Descargar en App Store" />
        </a>
      </div>
    </div>
  );
}

export default App;