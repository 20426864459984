import React, { useState, useEffect } from 'react';

const ImageComponent = ({ src, alt, defaultSrc, cssclass }) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleImageError = () => {
    if (imageSrc !== defaultSrc) {
      setImageSrc(defaultSrc);
    }
  };

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  return (
    <img
      src={imageSrc}
      alt={alt}
      onError={handleImageError}
      className={cssclass}
      onDragStart={(e) => e.preventDefault()}
    />
  );
};

export default ImageComponent;
