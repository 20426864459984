import React, { useState, useEffect } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import NotificationModal from "../layouts/NotificationModal";
import ColorPages from "../layouts/Colorpages";
import Headerback from "../layouts/Headerback";

const MisInvitados = () => {
  const [invitados, setInvitados] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {}, []);

  const closeModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  return (
    <div>
      <ColorPages />
      <Headerback title="Mis invitados" />
      <div className="page">
        <div className="container-amigo mt-5">
          {invitados.length === 0 ? (
            <div className=" d-flex flex-column align-items-center justify-content-center">
              <div className="icon-background">
              <BsFillPersonFill className="icon-large" />
              </div>
              
              <p className="inter_20_normal mt-3">No tienes invitados</p>
              </div>
          ) : (
            <div className="row center">
              <div className="card_gray_light w-100 card_desktop text-center">
                <div className="mt-1 mb-1 qrcode-text">
                  {/* Contenido cuando hay invitados */}
                </div>
                <button className="copy-button">
                  <img
                    src={process.env.PUBLIC_URL + "/images/copiar.png"}
                    alt="Copiar"
                  />
                </button>
              </div>
              <button className="button-primary btn-compartir mt-3">
                Compartir
              </button>
              <p className="inter_14_normal gray">
                {/* Mensaje o contenido adicional */}
              </p>
            </div>
          )}
        </div>
        {showModal && (
          <NotificationModal message={modalMessage} onClose={closeModal} />
        )}
      </div>
    </div>
  );
};

export default MisInvitados;
