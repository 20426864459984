import React from 'react';
import { useNavigate } from 'react-router-dom';

const CategorySection = ({  categories , title }) => {
    const navigate = useNavigate();

if (categories.length==3)
{
  document.documentElement.style.setProperty('--whcategories', '34vw');
}

  const handleCategoryClick = (type_of_shops, code) => {
    if(type_of_shops==7)
    {
      navigate("/home/ExternalAliados");
    }else{
    navigate("/home/Category?code="+code);
    }
  };
  
  return (
    <div className="section-category">
      <div className="inter_18_gray left ml-4">{title}</div>
      <div className="container-inline w-100 mt-3 pl-2">
        {categories.map((category) => (
          <div  className='size-content-categories'  key={category.code} onClick={() => handleCategoryClick(category.type_of_shops,category.code)}>
            <div className="card_gray_dark_resize center"  style={{ backgroundColor: category.backcolor  , height: "90px" }}>
              <div >
            
      
              <img
                  src={category.image}
                  alt="Imagen"
                  className={"center p-0 " }

                  style={{ width: "auto", height: "60px", maxWidth: "100%", objectFit: "contain"}}
                />
                
              </div>
            </div>
            <span className="inter_12  mt-2"  style={{ color: category.text_color }}>{category.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategorySection;
