import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCart from '../utils/useCart';
const HeaderTitle = ({ title, totalcart=0 }) => {

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1)
  };
  const handleGogocart = () => {
    navigate("/home/Cart")
  };
  


  return (
    <div  className='w-100 bg-white ' style={{ position: 'fixed', top: 0, zIndex: 999 }}>
     <div className="banner">
        <div className="left-div"  onClick={handleGoBack}>
          <img
            src={process.env.PUBLIC_URL + '/images/img-back.png'}
            alt="Slider 1"
            className='back-icon'
            style={{ width: '28px', height: 'auto' }}
          />
        </div>
        <div className="center-div align-items-center">
        <div className="header-title center w-100 color-responsive">{title}</div>
        </div>
        <div className="right-div"   onClick={handleGogocart}> 
        <img
            src={process.env.PUBLIC_URL + '/images/cart.png'}
            alt="Slider 1"
            className='cart-icon'
            style={{ width: '28px', height: 'auto' }}
          />
           <span className='cantidad' id='cantidad'>{totalcart}</span>
        </div>
      </div>
  </div>
  );
};

export default HeaderTitle;
