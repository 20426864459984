import React, { useEffect, useState } from "react";
import Headerback from "../layouts/Headerback";
import ColorPages from "../layouts/Colorpages";

const PagoRegalo = () => {
  const [emailFocused, setEmailFocused] = useState(false);
  const [nombreFocused, setnombreFocused] = useState(false);
  const [mensajeFocused, setmensajeFocused] = useState(false);

  const handleInputFocus = (event) => {
    const { name } = event.target;

    if (name === "nombre") {
      setnombreFocused(true);
    } else if (name === "email") {
      setEmailFocused(true);
    } else if (name === "mensaje") {
      setmensajeFocused(true);
    }
  };
  return (
    <div className="container">
      <div>
        <ColorPages />
        <Headerback title="¡Envia un Regalo!" />
      </div>
      <div className="row mt-3 align-items-center">
        <div className="col-7 mt-5 inter_12 left ml-2 ">
          <span> Confirma los detalles y añade un mensaje especial </span>
        </div>
        <div className="col-3 mt-5 inter_14_gray right mr-2  ">
          <img
            width="48"
            height="48"
            src="https://img.icons8.com/emoji/48/wrapped-gift.png"
            alt="wrapped-gift"
          />
        </div>
      </div>
      <br />
      <div>
        <form>
          <div className="">
            <div
              className={`form-input-container ${
                nombreFocused ? "input-filled" : ""
              }`}
            >
              <div className=" form-input-container">
                <label className="form-label">Nombre destinatario</label>
                <input
                  type="text"
                  name="nombre"
                  onFocus={handleInputFocus}
                  className="form-input"
                />
              </div>
            </div>
            <div
              className={` form-input-container ${
                emailFocused ? "input-filled" : ""
              }`}
            >
              <label className="form-label">Email</label>
              <input
                type="email"
                name="email"
                onFocus={handleInputFocus}
                className="form-input"
              />
            </div>
            <br />
            <div
              className={` form-input-container m-0 ${
                mensajeFocused ? "input-filled" : ""
              }`}
            >
              <label className="form-label" style={{ top: "5px" }}>
                Personaliza tu mensaje!
              </label>
              <textarea
                name="mensaje"
                onFocus={handleInputFocus}
                cols="30"
                rows="10"
                className="form-input"
                style={{
                  border: "1px solid var(--primary-color)",
                  borderRadius: "0 0 0px 0px",
                  width: "100%",
                }}
              ></textarea>
            </div>
          </div>
        </form>
        <div className="row mt-3 align-items-center">
          <div className="col-12 left mb-3 ">
            <span className="inter_14_400 ml-3 "> ¡Elije tu tarjeta! </span>
            <br />
            <span className="inter_12 ml-4 "> Personaliza tu tarjeta </span>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
          <img
            width="50"
            height="50"
            src="https://cdn.pixabay.com/photo/2017/07/21/23/38/map-2527432_1280.jpg"
            alt="wrapped-gift"
            style={{
              borderRadius: 5,
              border: "2px solid var(--primary-color)",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          />
             </div>
          <div className="col"> 
          <img
            width="50"
            height="50"
            src="https://cdn.pixabay.com/photo/2017/12/14/02/05/background-3018089_640.jpg"
            alt="wrapped-gift"
            style={{
              borderRadius: 5,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          />
          </div>
          <div className="col">
          <img
            width="50"
            height="50"
            src="https://cdn.pixabay.com/photo/2014/11/12/07/37/gift-528003_640.jpg"
            alt="wrapped-gift"
            style={{
              borderRadius: 5,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          />
            </div>
        </div>
        <div className="row  inter_14_gray right align-items-center ">
          <img
            width="300"
            height="180"
            src="https://cdn.pixabay.com/photo/2017/07/21/23/38/map-2527432_1280.jpg"
            alt="wrapped-gift"
            
            style={{
              borderRadius: 10,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PagoRegalo;
