import React from 'react';

const TabLayoutLocation_open_google = ({ title, active, handleTabClick, locations }) => {

  const openGoogleMaps = (latitude, longitude) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');
  };

  return (
    <li style={{ marginTop:"32px" }} className={` p-0 m-0 tab-item ${active ? 'active' : ''}`} onClick={handleTabClick}>
      <div className="tab-header">
        <div className="inter_14_500 color-primary">{title}</div>
        <img
          src={`${process.env.PUBLIC_URL}/images/${active ? 'closed.png' : 'closed.png'}`}
          alt="Estado del acordeón"
          className={active ? 'rotate-icon' : ''}
        />
      </div>
      {active && (
        <div className="tab-content  p-0 m-0 mt-3">
          {/* Mostrar la información de las ubicaciones */}
          {locations && (
            <div>
              <ul>
                {locations.map((location) => (
                  <li key={location.address} className='m-0 p-0'>
                    <a onClick={() => openGoogleMaps(location.latitude, location.longitude)}
                      target="_blank"
                      rel="noopener noreferrer">
                      <p className='inter_12 left p-0 mt-2 gray-dark'> {location.place} {location.address}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </li>
  );
};

export default TabLayoutLocation_open_google;
