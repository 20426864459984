import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Importa el CSS de react-toastify

const useCartrefacil = () => {

  const [cartList, setCartList] = useState(() => {
    const savedCartList = JSON.parse(localStorage.getItem('cartListrefacil'));
    return savedCartList ? savedCartList : [];
  });

  useEffect(() => {
    // Cargar el carrito desde el localStorage al montar el componente
    const savedCartList = JSON.parse(localStorage.getItem('cartListrefacil'));
    if (savedCartList) {
      setCartList(savedCartList);
    }
  }, []);

  const addToCart = (data) => {
    // Lógica para agregar un elemento al carrito.  
    // Verifica si el carrito tiene algún elemento antes de usar el método find
    const existingCart = cartList[0];
    if (existingCart) {

      const existingProduct = existingCart.products.find(
        (element) => element.actual_code_product === data.actual_code_product
      );

      if (existingProduct) {
        // Producto existente en el carrito, actualiza la cantidad
        existingProduct.quantity = data.quantity;
        existingProduct.total_price = existingProduct.price * data.quantity;
   
      } else {
        // Producto no existe en el carrito, agrega el producto
        const newProduct = {
          id_items: data.id_items,
          actual_code_product: data.actual_code_product,
          title: data.title,
          quantity: data.quantity,
          price: data.price,
          total_price: data.total_price,
          image: data.image,
        };

        cartList[0].products.push(newProduct);
      }

      // Actualiza el carrito en el LocalStorage
      const cantidadTotal = cartList[0].products.reduce((total, product) => total + product.quantity, 0);
      const total_price = cartList[0].products.reduce((total, product) => total + product.total_price, 0);
      cartList[0].cart.cantidad_total = cantidadTotal;
      cartList[0].cart.total_price = total_price;

      localStorage.setItem('cartListrefacil', JSON.stringify(cartList));

      // Actualiza el total del carrito
      total_cart();
    } else {
      // No hay carrito, crea uno nuevo

      const newCartList = [
        {
          cart: { cantidad_total: data.quantity, total_price: data.total_price },
          products: [
            {
              id_items: data.id_items,
              actual_code_product: data.actual_code_product,
              title: data.title,
              quantity: data.quantity,
              price: data.price,
              total_price: data.total_price,
              image: data.image,
            },
          ],
        },
      ];

      setCartList(newCartList);

      // Almacena el carrito en el LocalStorage
      localStorage.setItem('cartListrefacil', JSON.stringify(newCartList));

      // Actualiza el total del carrito
      total_cart();
    }

    // Muestra el toast indicando que el producto ha sido agregado al carrito
    toast.success('Producto agregado al carrito', { position: 'top-right' });
  };


  const removeFromCart = (data) => {
    // Lógica para remover un elemento del carrito
    // Puedes utilizar el hook useState aquí para actualizar el carrito
    const newCartList = cartList.map((cartItem) => {
      return {
        ...cartItem,
        products: cartItem.products.filter(
          (product) => product.actual_code_product !== data.actual_code_product
        ),
      };
    });

    newCartList[0].cart.cantidad_total = newCartList[0].products.reduce((total, product) => total + product.quantity, 0);
    newCartList[0].cart.total_price = newCartList[0].products.reduce((total, product) => total + product.total_price, 0);
    //actualizar cantidad total y total del carrito.
    setCartList(newCartList);
    localStorage.setItem('cartListrefacil', JSON.stringify(newCartList));
    // Muestra un toast para notificar que el producto fue removido del carrito
    toast.error('Producto removido del carrito', { position: 'top-right' });
  };

  const clearCart = () => {
    // Lógica para limpiar el carrito
    // Puedes utilizar el hook useState aquí para actualizar el carrito
    setCartList([]);
    localStorage.removeItem('cartListrefacil');

    // Muestra un toast para notificar que el carrito fue limpiado
    toast.info('Carrito limpiado', { position: 'top-right' });
  };

  const total_cart = () => {
    // Lógica para obtener el total de elementos en el carrito
    // Puedes utilizar el hook useState aquí para acceder al estado del carrito
    const cantidadTotal = cartList.reduce((total, item) => total + item.cart.cantidad_total, 0);
    return cantidadTotal;
  };

  const total_cart_total_value = () => {
    // Lógica para obtener el valor total del carrito
    // Puedes utilizar el hook useState aquí para acceder al estado del carrito
    const precioTotal = cartList.reduce((total, item) => total + item.cart.total_price, 0);
    return precioTotal;
  };

  const get_cart = () => {
    // Lógica para obtener el carrito

    return cartList;
 
  };



  const allcart = () => {
    const d =JSON.parse(localStorage.getItem('cartListrefacil'));;
    return d;
  }
  const total_cart_total = () => {
    // Lógica para obtener el valor total del carrito formateado
    // Puedes utilizar el hook useState aquí para acceder al estado del carrito
    const precioTotal = total_cart_total_value();
    return precioTotal;
  };


  // Retorna el estado actual del carrito y las funciones para modificarlo
  return {
    get_cart,
    cartList,
    addToCart,
    removeFromCart,
    clearCart,
    total_cart,
    total_cart_total_value,
    total_cart_total,
    allcart,
  };
};

export default useCartrefacil;
