import numeral from 'numeral';
import React, { useState } from 'react';

const ValueReload = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const handleClick = (value) => {
    setSelectedValue(value);
    console.log(value); // Aquí puedes realizar cualquier otra acción que necesites
  };

  const values = [1000, 2000, 3000,5000, 10000, 20000, 30000, 50000, 60000];

  const buttons = values.map((value) => (
    <div 
    className='col-3 mt-3'>
    <div className='card-refacil-recargas' style={{border: `3px solid ${selectedValue === value ? 'var(--primary-color)' : 'transparent'}`,
    }} 
    key={value} onClick={() => handleClick(value)}>
      {numeral(value).format('$0,0')}
    </div>
    </div>
  ));

  return <div className="row col-12">{buttons}</div>;
};

export default ValueReload;