import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ItemProductBono from "../layouts/ItemProductBono";
import { postarray } from "../../apiService";
import ColorPages from "../layouts/Colorpages";
import HeaderBonos from "../layouts/HeaderBonos";

const BonosComercio = () => {
  const [bono, setBono] = useState({});
  const [productos, setProductos] = useState([]);
  const [productostwo, setProductostwo] = useState([]);
  const [cashbackespecial, setCashbackespecial] = useState([]);
  const [cashbackespecialtwo, setCashbackespecialtwo] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");

  const fetchData = async () => {
    try {
      const bonoReponse = await postarray(
        { id_items: code },
        "bonoecommerce_v3"
      );
      setBono(bonoReponse);
      setCashbackespecial(bonoReponse.cashbackespecial.productsone);
      setCashbackespecialtwo(bonoReponse.cashbackespecial.productstwo);
      setProductos(bonoReponse.products.productsone);
      setProductostwo(bonoReponse.products.productstwo);
    } catch (error) {
      console.error(error);
    }
    return () => {};
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ColorPages />
      <HeaderBonos title=" " />
      <div className="page ml-3">
        <div className="image-container-comercio mt-3">
          <img
            className="centered-image-comercio"
            src={bono.image}
            alt={bono.title}
          />
        </div>
        <div className=" w-100">
          <div className=" inter_30_700 gray-dark w-100 left">{bono.title}</div>
          <div className="inter_12 gray w-100 left mt-2">
            {bono.subtitleapp}
          </div>
        </div>
        {productos.length == 0 && cashbackespecial.length == 0 && (
          <div style={{ marginTop: "40px" }}>
            Oops! No hay productos en este momento
          </div>
        )}
        <div style={{ marginTop: "40px" }}>
          <ItemProductBono title="Productos destacados" data={productos} />
        </div>
        <div style={{ marginTop: "40px" }}>
          {productostwo.length > 0 && (
            <ItemProductBono title="" data={productostwo} />
          )}
        </div>
        <div style={{ marginTop: "40px" }}>
          <ItemProductBono
            title="Cashbacks especiales para ti"
            data={cashbackespecial}
          />
        </div>
        <div style={{ marginTop: "40px" }}>
          {cashbackespecialtwo.length > 0 && (
            <ItemProductBono title="" data={cashbackespecialtwo} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BonosComercio;
