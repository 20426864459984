import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const TabLayoutHorarios = ({ title, active, handleTabClick, locations,code }) => {
  const navigate = useNavigate();
  const goubicaciones = () => {
    const randomNumber = Math.floor(Math.random() * 1000);
    navigate(`/home/Ubicaciones?code=${code}&rand=${randomNumber}`);
 
  };

  return (
    <li style={{ marginTop:"32px" }} className={` p-0 m-0 tab-item ${active ? 'active' : ''}`} onClick={handleTabClick}>
      <div className="tab-header">
        <div className="inter_14_500 color-primary">{title}</div>
        <img
          src={`${process.env.PUBLIC_URL}/images/${active ? 'closed.png' : 'closed.png'}`}
          alt="Estado del acordeón"
          className={active ? 'rotate-icon' : ''}
        />
      </div>
      {active && (
        <div className="tab-content  p-0 m-0 mt-3">
          {/* Mostrar la información de las ubicaciones */}
          {locations && (
            <div>
              <ul>
                {locations.map((location) => (
                  <li key={location.address} className='m-0 p-0'>
                    <a onClick={() => goubicaciones()}
                      target="_blank"
                      rel="noopener noreferrer">
                         <p className='inter_12 left p-0 gray-dark'> {location.title} </p>
                      <p className='inter_12 left p-0 mt-2 gray-dark'>  {location.time_text}</p>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </li>
  );
};

export default TabLayoutHorarios;
