
import React, { useEffect, useState } from "react";
import { postarray, getdata } from "../../apiService";
import { useNavigate } from "react-router-dom"
import ItemProduct from "./ItemProduct";
const CardsBono = () => {
    const [bonoOne, setBonoOne] = useState([]);
    const [bonoTwo, setBonoTwo] = useState([]);
    const navigate = useNavigate();

    const fetchData = async () => {
      try {
        const bonosDestacados = await getdata("bonoshome");
        const bonoReponse = await postarray({ id_items: 28 }, "bonoecommerce_v3");
        setBonoOne(bonosDestacados.destacados.productsone);
        setBonoTwo(bonosDestacados.destacados.productstwo);
      } catch (error) {
        console.error(error);
      }
      return () => {}
    };
    
    useEffect(() => {
        fetchData();
      }, []);

      const handleGoToAnimatedComponent = () => {
        navigate("/home/MisBonos");
      };
  return (
    <div className="px-3">
       <ItemProduct title="" data={bonoOne} />
       {bonoTwo.length > 0 && <ItemProduct title="" data={bonoTwo} />}
      </div>
  )
}

export default CardsBono