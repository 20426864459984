import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderTitle from "../layouts/HeaderTitle";
import ColorPages from "../layouts/Colorpages";
import Headerback from "../layouts/Headerback";
import { getsingle, post, postReponse } from "../../apiService";
import NotificationModal from "../layouts/NotificationModal";

const Perfil = () => {
  const navigate = useNavigate();

  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [numeroDocumento, setNumeroDocumento] = useState("");
  const [telefono, setTelefono] = useState("");
  const [cumpleanos, setCumpleanos] = useState("");

  const [nombresError, setNombresError] = useState("");
  const [apellidosError, setApellidosError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [tipoDocumentoError, setTipoDocumentoError] = useState("");
  const [numeroDocumentoError, setNumeroDocumentoError] = useState("");
  const [telefonoError, setTelefonoError] = useState("");
  const [cumpleanosError, setCumpleanosError] = useState("");

  const [emailFocused, setEmailFocused] = useState(false);
  const [nombresFocused, setNombresFocused] = useState(false);
  const [apellidosFocused, setApellidosFocused] = useState(false);
  const [numeroDocumentoFocused, setNumeroDocumentoFocused] = useState(false);
  const [telefonoFocused, setTelefonoFocused] = useState(false);
  const [cumpleanosFocused, setCumpleanosFocused] = useState(false);
  const [dataNotification, setdataNotification] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  
  const fetchData = async () => {
    try {
      const info = await getsingle("me_personal_info");
      setNombres(info.first_name);
      setNombresFocused(true);

      setApellidos(info.surname);
      setApellidosFocused(true);

      setEmail(info.email);
      setEmailFocused(true);

      setTipoDocumento(info.id_document_type);
      setTelefonoFocused(true);

      setNumeroDocumento(info.document_number);
      setNumeroDocumentoFocused(true);

      setTelefono(info.cell_phone);
      setTelefonoFocused(true);

      setCumpleanos(info.birthdate);
      setCumpleanosFocused(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTouchStart = (event) => {
    event.preventDefault();
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
  };

  const handleWheel = (event) => {
    event.preventDefault();
  };
  const handleInputFocus = (event) => {
    const { name } = event.target;

    if (name === "email") {
      setEmailFocused(true);
    } else if (name === "nombres") {
      setNombresFocused(true);
    } else if (name === "apellidos") {
      setApellidosFocused(true);
    } else if (name === "numeroDocumento") {
      setNumeroDocumentoFocused(true);
    } else if (name === "telefono") {
      setTelefonoFocused(true);
    } else if (name === "cumpleanos") {
      setCumpleanosFocused(true);
    }
    // Agregar más condiciones para otros campos si es necesario
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "nombres") {
      setNombres(value);
    } else if (name === "apellidos") {
      setApellidos(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "tipoDocumento") {
      setTipoDocumento(value);
    } else if (name === "numeroDocumento") {
      setNumeroDocumento(value);
    } else if (name === "telefono") {
      setTelefono(value);
    } else if (name === "cumpleanos") {
      setCumpleanos(value);
    }
    // Agregar más condiciones para otros campos si es necesario
  };
  const handleInputBlur = (event) => {
    const { name, value } = event.target;

    if (name === "nombres") {
      setNombresError(value.trim() === "" ? "Este campo es obligatorio" : "");
      setNombresFocused(false);
      if (value !== "") {
        setNombresFocused(true);
      }
    } else if (name === "apellidos") {
      setApellidosError(value.trim() === "" ? "Este campo es obligatorio" : "");
      setApellidosFocused(false);
      if (value !== "") {
        setApellidosFocused(true);
      }
    } else if (name === "email") {
      setEmailError(value.trim() === "" ? "Este campo es obligatorio" : "");
      setEmailFocused(false);
      if (value !== "") {
        setEmailFocused(true);
      }
    } else if (name === "tipoDocumento") {
      setTipoDocumentoError(
        value.trim() === "" ? "Este campo es obligatorio" : ""
      );
    } else if (name === "numeroDocumento") {
      setNumeroDocumentoError(
        value.trim() === "" ? "Este campo es obligatorio" : ""
      );
      setNumeroDocumentoFocused(false);
      if (value !== "") {
        setNumeroDocumentoFocused(true);
      }
    } else if (name === "telefono") {
      setTelefonoError(value.trim() === "" ? "Este campo es obligatorio" : "");
      setTelefonoFocused(false);
      if (value !== "") {
        setTelefonoFocused(true);
      }
    } else if (name === "cumpleanos") {
      setCumpleanosError(
        value.trim() === "" ? "Este campo es obligatorio" : ""
      );
      setCumpleanosFocused(false);
      if (value !== "") {
        setCumpleanosFocused(true);
      }
    }
  };

  const goIntereses = (event) => {
    navigate("/home/Misintereses");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let hasError = false;

    if (nombres.trim() === "") {
      setNombresError("Este campo es obligatorio");
      hasError = true;
    } else {
      setNombresError("");
    }

    if (apellidos.trim() === "") {
      setApellidosError("Este campo es obligatorio");
      hasError = true;
    } else {
      setApellidosError("");
    }

    if (email.trim() === "") {
      setEmailError("Este campo es obligatorio");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (tipoDocumento.toString().trim() === "") {
      setTipoDocumentoError("Este campo es obligatorio");
      hasError = true;
    } else {
      setTipoDocumentoError("");
    }

    if (numeroDocumento.trim() === "") {
      setNumeroDocumentoError("Este campo es obligatorio");
      hasError = true;
    } else {
      setNumeroDocumentoError("");
    }

    if (telefono.trim() === "") {
      setTelefonoError("Este campo es obligatorio");
      hasError = true;
    } else {
      setTelefonoError("");
    }

    if (cumpleanos.trim() === "") {
      setCumpleanosError("Este campo es obligatorio");
      hasError = true;
    } else {
      setCumpleanosError("");
    }

    // Validación del formato de email
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setEmailError("Ingrese un email válido");
      hasError = true;
    }

    // Validación del número de teléfono
    if (telefono.length !== 10 || isNaN(telefono)) {
      setTelefonoError("Ingrese un número de teléfono válido (10 dígitos)");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const fetchData = async () => {
      try {
        await post(
          {
            first_name: nombres,
            surname: apellidos,
            email: email,
            id_document_type: tipoDocumento,
            document_number: numeroDocumento,
            cell_phone: telefono,
            birthdate: cumpleanos,
          },
          "prospects_update"
        );
        setShowNotification(true);

        setdataNotification("Datos actualizados correctamente");
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
    navigate("/home/Menu");
  };

  return (
    <div>
      {showNotification && (
        <NotificationModal
          message={dataNotification}
          onClose={handleCloseNotification}
        />
      )}
      <ColorPages />
      <Headerback title="Informacion Personal" />
      <div className="page">
        <div className="card-perfil">
          <div className="perfil-container">
        <div className="card_main perfil-responsive  mt-0 p-0 mx-4 ">
          <div className="container row p-0 m-0">
            <div className="col-4 col-md-6 pl-4 pt-2 left">
              <img
                src={process.env.PUBLIC_URL + "/images/user.png"}
                alt="Slider 1"
                style={{ width: "91px", height: "auto" }}
              />
            </div>
            <div className="col p-0 m-0 d-flex flex-column justify-content-end justify-content-md-center  align-items-center ">
              <div
                className="inter_20_500 color-white w-100 left mb-4 ml-3"
                style={{ height: "20px" }}
              >
                TU PERFIL
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="col container  p-5 d-flex flex-column justify-content-end letf">
          {/* Resto del contenido del perfil */}
          <form className="perfil-form">
            <div
              className={`w-100 form-input-container ${nombresFocused ? "input-filled" : ""
                }`}
            >
              <label className="form-label">Nombres</label>
              <input
                type="text"
                name="nombres"
                value={nombres}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="nombres"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {nombresError && (
                <span className="error-label">{nombresError}</span>
              )}
            </div>
            <div
              className={`w-100 form-input-container ${apellidosFocused ? "input-filled" : ""
                }`}
            >
              <label className="form-label">Apellidos</label>
              <input
                type="text"
                name="apellidos"
                value={apellidos}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="apellidos"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {apellidosError && (
                <span className="error-label">{apellidosError}</span>
              )}
            </div>
            <div
              className={`w-100 form-input-container ${emailFocused ? "input-filled" : ""
                }`}
            >
              <label className="form-label">Email</label>
              <input
                type="email"
                name="email"
                value={email}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="email"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {emailError && <span className="error-label">{emailError}</span>}
            </div>

            <div
              className={`w-100 form-input-container ${tipoDocumentoError ? "input-filled" : ""
                }`}
            >
              <label className="form-label"></label>
              <select
                name="tipoDocumento"
                value={tipoDocumento}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="tipoDocumento"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              >
                <option value="cc">Cédula de Ciudadanía</option>
                <option value="ce">Cédula de Extranjería</option>
              </select>
              {tipoDocumentoError && (
                <span className="error-label">{tipoDocumentoError}</span>
              )}
            </div>

            <div
              className={`w-100 form-input-container ${numeroDocumentoFocused ? "input-filled" : ""
                }`}
            >
              <label className="form-label">Número de Documento</label>
              <input
                type="text"
                name="numeroDocumento"
                value={numeroDocumento}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="numeroDocumento"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {numeroDocumentoError && (
                <span className="error-label">{numeroDocumentoError}</span>
              )}
            </div>
            <div
              className={`w-100 form-input-container ${telefonoFocused ? "input-filled" : ""
                }`}
            >
              <label className="form-label">Teléfono</label>
              <input
                type="tel"
                name="telefono"
                value={telefono}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="telefono"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {telefonoError && (
                <span className="error-label">{telefonoError}</span>
              )}
            </div>
            <div className={`w-100 form-input-container input-filled`}>
              <label className="form-label">Cumpleaños</label>
              <input
                type="date"
                name="cumpleanos"
                value={cumpleanos}
                onFocus={handleInputFocus}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="form-input disable-zoom"
                id="cumpleanos"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onWheel={handleWheel}
              />
              {cumpleanosError && (
                <span className="error-label">{cumpleanosError}</span>
              )}
            </div>
            

            <button
              type="button"
              onClick={handleSubmit}
              className="button-primary"
            >
              Guardar
            </button>

            {/*  <div className='center mt-4'>
              <div className='card_gray_hand p-1' onClick={goIntereses}>

                <img
                  className='mx-3 my-1'
                  src='../images/uphand.png'
                  alt="Fleksi Logo"
                  style={{ width: '76px', height: 'auto' }}
                />

              </div>
            </div>
              */}
          </form>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Perfil;
