import { useState, useEffect } from "react";
import NotificationModal from "../layouts/NotificationModal";
import { useNavigate } from "react-router-dom";
import { getdata, getsingle, post, postReponse } from "../../apiService";
import Headerback from "../layouts/Headerback";

const NotificacionDecomunicacion = () => {
  const navigate = useNavigate();
  const [emailChecked, setEmailChecked] = useState(false);
  const [mensajeTextoChecked, setMensajeTextoChecked] = useState(false);
  const [whatsappChecked, setWhatsappChecked] = useState(false);
  const [pushChecked, setPushChecked] = useState(false);
  const [dataNotification, setdataNotification] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getdata("getPreferences");
      const preferencesData = response.data;

      
      setEmailChecked(preferencesData.prefers_email === 1);
      setMensajeTextoChecked(preferencesData.prefers_sms === 1);
      setWhatsappChecked(preferencesData.prefers_whatsapp === 1);
      setPushChecked(preferencesData.prefers_push_notifications === 1);
    } catch (error) {
      console.error(error);
    }
    return () => {}
  };

  useEffect(() => {  
    fetchData();
  }, []); 
  const handleEmailChange = () => {
    setEmailChecked(!emailChecked);
  };

  const handleMensajeTextoChange = () => {
    setMensajeTextoChecked(!mensajeTextoChecked);
  };

  const handleWhatsappChange = () => {
    setWhatsappChecked(!whatsappChecked);
  };

  const handlePushChange = () => {
    setPushChecked(!pushChecked);
  };

  const handleSubmit = () => {
    const fetchData = async () => {
      try {
        await post(
          {
            prefers_email: emailChecked ? 1 : 2,
            prefers_sms: mensajeTextoChecked ? 1 : 2,
            prefers_whatsapp: whatsappChecked ? 1 : 2,
            prefers_push_notifications: pushChecked ? 1 : 2,
          },
          "setPreferences"
        );

        setShowNotification(true);
        setdataNotification("Notificaciones de comunicacion actualizadas");
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
    navigate("/home/NotificacionDecomunicacion");
  };

  return (
    <div className="container">
      {showNotification && (
        <NotificationModal
          message={dataNotification}
          onClose={handleCloseNotification}
        />
      )}
      <Headerback title="Notificaciones" />

      <div className="container mt-4">
        <div className="inter_14_500 " style={{ marginTop: "10px" }}>
          Autorizo el envío de información publicitaria y promocional a través de:
        </div>
        <div className="row mt-4 ">
          <div className="col-1">
            <input
              type="checkbox"
              checked={emailChecked}
              onChange={handleEmailChange}
            />
          </div>
          <div className="col left">
            <span className="inter_12_500">
              ¿Quieres recibir alertas por Correo Electronico?
            </span>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-1">
            <input
              type="checkbox"
              checked={mensajeTextoChecked}
              onChange={handleMensajeTextoChange}
            />
          </div>
          <div className="col left">
            <span className="inter_12_500">
              ¿Quieres recibir alertas por Mensaje de texto?
            </span>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-1">
            <input
              type="checkbox"
              checked={whatsappChecked}
              onChange={handleWhatsappChange}
            />
          </div>
          <div className="col left">
            <span className="inter_12_500">
              ¿Quieres recibir alertas por WhatsApp?
            </span>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-1">
            <input
              type="checkbox"
              checked={pushChecked}
              onChange={handlePushChange}
            />
          </div>
          <div className="col left mb-2">
            <span className="inter_12_500">¿Activar notificaciones push?</span>
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={handleSubmit}
        className="mt-3 m-auto button-primary "
      >
        Guardar
      </button>
    </div>
  );
};

export default NotificacionDecomunicacion;
