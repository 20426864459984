import React, { useEffect, useState } from "react";
import { postarray } from "../../apiService";
import { useNavigate } from "react-router-dom";
import Headerback from "../layouts/Headerback";
import CardsBono from "../layouts/CardsBono";
import ColorPages from "../layouts/Colorpages";

const MisBonos = () => {
  const [bono, setBono] = useState({});

  const fetchData = async () => {
    try {
      const bonoReponse = await postarray({ id_items: 28 }, "bonoecommerce_v3");
      setBono(bonoReponse);
    } catch (error) {
      console.error(error);
    }
    return () => {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container">
      <div>
        <ColorPages />
        <Headerback title="Mis Bonos" />
      </div>
      <div className="mt-5">
        <CardsBono />
      </div>
      <div className="px-3 mt-5 ">
       
          <div className="cardPago row " style={{ backgroundColor: "#E7E7E7", borderColor: "#E7E7E7"}}>
            <div
              className="col-12 cardPago mr-5 ml-3 mt-2 mb-3 d-flex justify-content-start"
              style={{ backgroundColor: "white" }}
            >
              <img
                src={bono.image}
                alt=""
                className="img-fluid"
                width= "100%"
              />
            </div>
         
            <div className="col-12 body">
              <p className="inter_20_normal left ">Bono de Regalo</p>
              <p
                className="inter_12 left gray"
                style={{ whiteSpace: "nowrap" }}
                >
                Vence 5/Febrero/2023
              </p>
              <p className="inter_14_600 left gray">
                50.000
              </p>
            </div>
          
        </div>
        
        <div className="row ">
          <div className="col footer d-flex align-items-center justify-content-start inter_14 gray">
            <div className="mt-2">Bono de Regalo</div>
          </div>
        </div>
        <div className="row ">
          <div
            className="col footer d-flex align-items-center justify-content-start inter_14 gray"
            style={{ backgroundColor: "#BCF5D5", zIndex: -2 }}
          >
            <div className="mt-2">Bono de Regalo</div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default MisBonos;
