import React from 'react';
import { useNavigate } from 'react-router-dom';

const Itemhistorialdecompras = ({transactions}) => {
  const navigate = useNavigate();
  const handleItemClick = (transactionId) => {
    // Realiza la acción deseada al hacer clic en el elemento
    //navigate(`/transaccion/${transactionId}`);
  //segun lo que compro o lo que hizo  navigate("/home/HistoricoProductofisico")
  };

  
  return (
    <>
   
   <div className='row d-none d-md-block'>
        <div className='col-12'>
          <div className='left'>
            <h1>Mis Puntos</h1>
          </div>
        </div>
      </div>
      {transactions.map(transaction => (
        
        <div className='row  mt-4 MisPointsResponsive ' key={transaction.id}    onClick={() => handleItemClick(transaction.id)}
    >
      
          <div className='col-3 col-md-5 center'>
            <div className="card-rounded center" style={{ }}>
              <div className="image-container">
                <img src={transaction.logo} className="centered-image p-0" alt="Imagen" />
              </div>
            </div>
          </div>
      
          <div className='col left ml-5 mx-md-5 '>
            <p className='inter_16_500'>{transaction.shop}</p>
            <p className='inter_12_400 mt-1 color-gray-light'>NO {transaction.invoice_number}</p>
            <p className='inter_12_400 mt-1 color-gray-light'>Fecha {transaction.transaction_date}</p>
            <p className='inter_12_400 mt-1 color-gray-light'>Valor compra: {transaction.value_with_iva}</p>
            {transaction.points_spent > 0 && 
            <p className='inter_14_400 mt-1 color-green'>Puntos redimidos: {transaction.points_spent}</p>}

{transaction.puntoscargados > 0 && 
             <p className='inter_14_400 mt-1 color-green'>Puntos cargados: {transaction.puntoscargados}</p>
}
{transaction.puntosenvalidacion > 0 && 
            <p className='inter_14_400 mt-1 color-primary'>Puntos en validación: {transaction.puntosenvalidacion}</p>
}


          </div>
        </div>
      ))}
     
    </>
  );
};

export default Itemhistorialdecompras;
