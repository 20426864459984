import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import ColorPages from "../layouts/Colorpages";
import Headerback from "../layouts/Headerback";
import { getdata, post, postarray, postsingle } from "../../apiService";
import NotificationModal from "../layouts/NotificationModal";

const CentroNotificaciones = () => {
  const [sms, setSms] = useState(true);
  const [email, setEmail] = useState(true);
  const [push, setPush] = useState(true);
  const [call, setCall] = useState(true);
  const [whatsapp, setWhatsapp] = useState(true);
  const [modalMessage, setModalMessage] = useState(""); 
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getdata("getPreferences");
        const preferences = response.data;
  
        setSms(preferences.prefers_sms === 1);
        setEmail(preferences.prefers_email === 1);
        setPush(preferences.prefers_push_notifications === 1);
        setCall(preferences.prefers_call_me === 1);
        setWhatsapp(preferences.prefers_whatsapp === 1);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);
  const GuardarPreferences = async () => {

    const preferences = {
      prefers_email: email ? 1 : 2,
      prefers_sms: sms ? 1 : 2,
      prefers_whatsapp: whatsapp ? 1 : 2,
      prefers_push_notifications: push ? 1 : 2,
      call_me: call ? 1 : 2,
    };

    try {
      const response = await post( preferences,"setPreferences"); 
      console.log(response);
      setModalMessage("Configuracion de notificaciones guardadas correctamente");
      setShowModal(true);
    } catch (error) {
      setModalMessage("No se pudo guardar la configuración de notificaciones");
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <ColorPages />
      <Headerback title="Configuracion de notificaciones" />
      <div className="page">
        <div className="container container-desktop p-4 mt-4 ">
          <h4 className="notification-title">
            Administra las notificaciones promocionales que quieres recibir de
            nosotros
          </h4>
          <div className="notification-item">
            <span>SMS</span>
            <Switch
              onChange={setSms}
              checked={sms}
              onColor="#53B5F6"
              offColor="#ccc"
            />
          </div>
          <div className="notification-item">
            <span>Correos electrónicos</span>
            <Switch
              onChange={setEmail}
              checked={email}
              onColor="#53B5F6"
              offColor="#ccc"
            />
          </div>
          <div className="notification-item">
            <span>Notificaciones push</span>
            <Switch
              onChange={setPush}
              checked={push}
              onColor="#53B5F6"
              offColor="#ccc"
            />
          </div>
          <div className="notification-item">
            <span>Llamada telefónica</span>
            <Switch
              onChange={setCall}
              checked={call}
              onColor="#53B5F6"
              offColor="#ccc"
            />
          </div>
          <div className="notification-item">
            <span>Whatsapp</span>
            <Switch
              onChange={setWhatsapp}
              checked={whatsapp}
              onColor="#53B5F6"
              offColor="#ccc"
            />
          </div>
          <button className="save-button"  onClick={GuardarPreferences}>
            GUARDAR
          </button>
        </div>
      </div>
      {showModal && (
        <NotificationModal message={modalMessage} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default CentroNotificaciones;
