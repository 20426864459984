import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Headerback = ({ title }) => {

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1)
  };



  return (
    <div  className='w-100 bg-white ' style={{ position: 'fixed', top: 0, zIndex: 999 }}>
     <div className="banner">
        <div className="left-div"  onClick={handleGoBack}>
          <img
            src={process.env.PUBLIC_URL + '/images/img-back.png'}
            alt="Slider 1"
            style={{ width: '28px', height: 'auto' }}
            className='back-icon'
          />
        </div>
        <div className="center-div align-items-center">
        <div className="header-title left gray-dark color-responsive center w-100">{title}</div>
        
        </div>
        <div className="right-div">

        </div>
      </div>
  </div>
  );
};

export default Headerback;
