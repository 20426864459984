import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { postarraynoauthg } from '../../apiService';
import ImageComponent from '../layouts/ImageComponent';
import ColorPages from '../layouts/Colorpages';

const SplashScreen = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  var code = queryParams.get('code');
  var referido = queryParams.get('referido');
  const [desing, setDesing] = useState({});
  let navigate = useNavigate();

  const tologin = useCallback((event) => {
    if (navigate) {
      navigate("/Session/Login");
    }
  }, [navigate]);

  const toregister = useCallback((event) => {
    if (navigate) {
      navigate("/Session/Register");
    }
  }, [navigate]);


  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (
        accessToken && // Verifica que accessToken no sea null, undefined, o una cadena vacía
        accessToken !== "null" && // Verifica que accessToken no sea la cadena "null"
        accessToken !== "undefined" && // Verifica que accessToken no sea la cadena "undefined"
        accessToken !== "" && // Verifica que accessToken no sea una cadena vacía
        accessToken.length > 20
      ) {
        window.location.href = "/Home";
      }

      if (referido != undefined || referido != null) {
      localStorage.setItem("referido", referido);
      }
      // Comprobar si el código es undefined o null.
      if (code == undefined || code == null) {
       // Obtener los datos de diseño del localStorage
       localStorage.setItem("code_aliado", 1);
       const desing = await postarraynoauthg({ identifier_code: 1 }, "allied_program_by_parameter");
       setDesing(desing);
       // Guardar en el localStorage
       localStorage.setItem('appDesign', JSON.stringify(desing));
       // Asignar los valores del objeto a las variables CSS
       document.documentElement.style.setProperty('--primary-color', desing.main_color || '#FF7D41');
       document.documentElement.style.setProperty('--splash-back-color', desing.color_back_splash || '#C8C8CB');
       document.documentElement.style.setProperty('--spinner-color', desing.color_loading );        
       localStorage.setItem("logo_superior_card", desing.logo_superior_card);
       localStorage.setItem("text_color_card", desing.text_color_card);
       localStorage.setItem("card_color", desing.card_color);      
       localStorage.setItem("text_color_percentage", desing.text_color_percentage);
       localStorage.setItem("image_percentage", desing.image_percentage);         
       localStorage.setItem("logo_inferior_card", desing.logo_inferior_card);
       localStorage.setItem("class_img_end_card", desing.class_img_end_card);
       localStorage.setItem("class_img_sup_card", desing.class_img_sup_card);
       localStorage.setItem("logo_splash", desing.logo_splash);
       localStorage.setItem("themecode", desing.name);

      } else {
        // Si no existe en el localStorage, obtener los datos y guardarlos
        localStorage.setItem("code_aliado", code);
        const desing = await postarraynoauthg({ identifier_code: code }, "allied_program_by_parameter");
        setDesing(desing);
        // Guardar en el localStorage
        localStorage.setItem('appDesign', JSON.stringify(desing));
        // Asignar los valores del objeto a las variables CSS
        document.documentElement.style.setProperty('--primary-color', desing.main_color || '#FF7D41');
        document.documentElement.style.setProperty('--splash-back-color', desing.color_back_splash || '#C8C8CB');
        document.documentElement.style.setProperty('--spinner-color', desing.color_loading );
      
        localStorage.setItem("logo_superior_card", desing.logo_superior_card);
        localStorage.setItem("logo_inferior_card", desing.logo_inferior_card);
        localStorage.setItem("text_color_card", desing.text_color_card);
        localStorage.setItem("card_color", desing.card_color);      
        localStorage.setItem("text_color_percentage", desing.text_color_percentage);
        localStorage.setItem("image_percentage", desing.image_percentage);      
        localStorage.setItem("logo_splash", desing.logo_splash);
        localStorage.setItem("themecode", desing.name);
      }

    } catch (error) {
    }
    
  };

  useEffect(() => {    
 
    fetchData(); 
    return () => {}
  },[]);


  return (
    <div className="bg_size bg-splashscreen">
      <div className="upper-50-half">
        <div className="image-container align-items-center center">
          <ImageComponent
            src={desing.logo_splash}
            alt={desing.name}
            defaultSrc={process.env.PUBLIC_URL + '/images/logo_fleksi_blanco.png'}
            cssclass={desing.class_logo_splash || 'w-50'} />
        </div>
      </div>

      <div className="lower-50-half border-box-top bg-white ">
        <div className="center-content">
          <label className='title'>Bienvenido</label>
          <label className='subtitle m-20' >Premiamos tu lealtad.</label>

          <button onClick={tologin}  className='button-primary   button-text mt-3'>Inicia sesión</button>
          <button  onClick={toregister} className='button-secundary-login  button-text bg-white mt-3' >Regístrate</button>
     
        </div>
      </div>

    </div>
  );
}

export default SplashScreen;
