
import React from "react";


function LoadingSpinner() {
  return (
    <div
   className="back-loading"
    >
      <div
        className="ball"
       
      ></div>
      <img
        className="base-image"
        src={process.env.PUBLIC_URL + '/images/baseloading.png'}
        alt="Imagen de Google"
        style={{ marginTop: "-20px" }}
      />
    </div>
  );
}

export default LoadingSpinner;
