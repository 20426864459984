import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postarray } from '../../apiService';
import ImageComponent from './ImageComponent';
import useCart from '../utils/useCart';

const HeaderBonos = ({ title }) => {
  const navigate = useNavigate();
  const { addToCart, total_cart } = useCart();
  const [totalcart, setTotalcart] = useState(0);

  useEffect(() => {
    setTotalcart(total_cart())
  })
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCardClick = (code) => {
 
    const randomNumber = Math.floor(Math.random() * 1000);
    setShowSuggestions(false)
    navigate(`/home/Bono?code=${code}&rand=${randomNumber}`);
 
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [resultados, setResultados] = useState([]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    try {
      const response = await postarray( { searchTerm: value },'searchProductsbonos');
      setSuggestions(response.results);
      setShowSuggestions(value !== '');
    } catch (error) {
      console.error(error);
    }
  };

  const handleGogocart = () => {
    navigate("/home/Cart")
  };


  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }

    return (
      <ul className="suggestions-list mt-2">
        {suggestions.map((suggestion, index) => (
          <li  className='left' key={index}  onClick={() => handleCardClick(suggestion.id_fleksi_products)}>
   
            <ImageComponent
                        src={suggestion.image}
                        alt={suggestion.title}
                        defaultSrc={process.env.PUBLIC_URL + '/images/defaultimage.png'}
                        cssclass={'img-search'} />

            <span  className='inter_12_500 ml-2'  
            style={{marginTop:'15px', position:'absolute'}}>  {suggestion.title}</span>
          </li>
        ))}
      </ul>
    );
  };



  return (
    <div  className='w-100 bg-white ' style={{ position: 'fixed', top: 0, zIndex: 999 }}>
    <div className="banner mb-4 mb-md-4 mb-lg-0">
    <div className="left-div p-3" onClick={handleGoBack}>
        <img
            src={process.env.PUBLIC_URL + '/images/img-back.png'}
            alt="Slider 1"
            className='back-icon'
            style={{ width: '28px', height: 'auto' }}
        />
    </div>
    <div className="center-div">
        <div className="search-container"  style={{ marginLeft: '50px' }}>
            <span className="search-icon">
                <img src={process.env.PUBLIC_URL + '/images/buscar.png'} alt="Buscar" />
            </span>
            <input
                style={{ marginTop: '5px', paddingLeft: '40px' }}
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                className="form-input form_rounded"
                placeholder="Buscar aquí"
            />
        </div>
    </div>
    <div className="right-div  pt-3"   onClick={handleGogocart}>
    
        <img
            src={process.env.PUBLIC_URL + '/images/cart.png'}
            alt="Slider 1"
            className='cart-icon'
            style={{ width: '28px', height: 'auto' }}
          />
           <span className='cantidad' id='cantidad'>{totalcart}</span>
    </div>
    {renderSuggestions()}
</div>
</div>
  );
};

export default HeaderBonos;
