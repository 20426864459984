import React, { useEffect, useState } from "react";
import Headerback from "../layouts/Headerback";
import ItemComercios from "../layouts/ItemComercios";
import { getdata } from "../../apiService";
import ItemProduct from "../layouts/ItemProduct";
import ItemProductBono from "../layouts/ItemProductBono";
import { useLocation } from "react-router-dom";
import CardComponentBilletera from "../layouts/CardComponentBilletera";
import ColorPages from "../layouts/Colorpages";
const BonosDestEsp = () => {
  const [comercios, setComercios] = useState([]);
  const [comerciostwo, setComerciostwo] = useState([]);
  const [cashbackespecialtwo, setCashbackespecialtwo] = useState([]);
  const [cashbackespecial, setCashbackespecial] = useState([]);
  const [lomasnuevotwo, setLomasnuevotwo] = useState([]);
  const [destacadostwo, setDestacadostwo] = useState([]);
  const [lomasnuevo, setLomasnuevo] = useState([]);
  const [destacados, setDestacados] = useState([]);  
  const location = useLocation();
  const { selectedCard } = location.state || {};

  const fetchData = async () => {
    try {
      const comerciosReponse = await getdata("comercios_aliados");
      setComercios(comerciosReponse.data.shops);
      setComerciostwo(comerciosReponse.data.shopstwo);

      const homeresponse = await getdata("home");
      setCashbackespecial(homeresponse.cashbackespecial.productsone);
      setCashbackespecialtwo(homeresponse.cashbackespecial.productstwo);

      const bonoresponse = await getdata("bonoshome");
      console.log(bonoresponse);
      setLomasnuevo(bonoresponse.lomasnuevo.productsone);
      setDestacados(bonoresponse.destacados.productsone);

      setLomasnuevotwo(bonoresponse.lomasnuevo.productstwo);
      setDestacadostwo(bonoresponse.destacados.productstwo);
    } catch (error) {
      console.error(error);
    }
    return () => {}
  };


  useEffect(() => {
    fetchData();
  }, []);

  return (
    
    <div className="container">
        <ColorPages/>
      <div>
        <Headerback title={selectedCard.title} />
      </div>
      <div className="row">
        <div className="col mt-5 pt-2 inter_20_normal left ml-2 ">
          <span> Bonos de destinacion específica </span>
        </div>
      </div>
      {selectedCard && (
        <CardComponentBilletera data={selectedCard} />
        
      )}
      
      <div className="row">
        <div className="col mt-5 pt-2 inter_20_normal left ml-2 ">
          <span> Comercios aliados </span>
        </div>
      </div>
      <div>
        <ItemComercios title="" data={comercios} />
        {comerciostwo.length > 0 && (
          <ItemComercios title="" data={comerciostwo} />
        )}
      </div>
      <div className="row">
        <div className="col mt-5 mb-3 pt-2 inter_20_normal left ml-2 ">
          <span> Cashbacks especiales para ti </span>
        </div>
      </div>
      <ItemProduct title="" data={cashbackespecial} />
      {cashbackespecialtwo.length > 0 && (
        <ItemProduct title="" data={cashbackespecialtwo} />
      )}
      <div className="row">
        <div className="col mt-5 mb-3 pt-2 inter_20_normal left ml-2 ">
          <span> Bonos </span>
        </div>
      </div>
      <div style={{ marginTop: "52px" }}>
        <ItemProductBono title="Productos destacados" data={destacados} />
        {destacadostwo.length > 0 && (
          <ItemProductBono title="" data={destacadostwo} />
        )}
      </div>
      <div className="mt-2">
        <ItemProductBono title="Lo mas nuevo" data={lomasnuevo} />
        {lomasnuevotwo.length > 0 && (
          <ItemProductBono title="" data={lomasnuevotwo} />
        )}
      </div>
    </div>
  );
};

export default BonosDestEsp;
