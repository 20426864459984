import React from 'react'
import ColorPages from '../layouts/Colorpages'
import Headerback from '../layouts/Headerback'

const Mensajes = () => {
  return (
    <div>
    <ColorPages />
    <Headerback title="Mensajes" />
    <div className="page">

    </div>
    </div>
  )
}

export default Mensajes