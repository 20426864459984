import React, { useEffect, useState } from "react";
import Headerback from "../layouts/Headerback";
import { getsingle, post } from "../../apiService";
import numeral from "numeral";

import { useNavigate } from "react-router-dom";
import ItemProductBonocart from "../layouts/ItemProductBonoCart";
import useCartRefacil from "../utils/useCartrefacil";
import ItemProductRefacilCart from "../layouts/ItemProductRefacilCart";
const Refacilcart = () => {
  const { addToCart, removeFromCart, cartList, total_cart } = useCartRefacil();
  const [products, setProducts] = useState([]);
  const [isVisiblePayu, setisVisiblePayu] = useState(false);
  const [isVisiblePayrewards, setisVisiblePayRewards] = useState(false);
  const [userPoints, setUserPoints] = useState({
    total: 0,
    name: "",
    puntosacanjear: 0,
    puntosrestantes: 0,
  });
  const [totacart, setTotalcart] = useState(0);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const fetchData = async () => {
    try {
      setProducts(cartList[0].products);
      const userPointsData = await getsingle("user_points_v2");
      setUserPoints(userPointsData);
      setTotalcart(cartList[0].cart.total_price);
      if (cartList[0].cart.cantidad_total == 0) {
        setTotalcart(0);
      }
    } catch (error) {
      console.error(error);
    }
     return () => {}
  };

  useEffect(() => {
    fetchData();
  }, []);



  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);

  };

  const handleGoPagar = () => {
    //post
    const fetchData = async () => {
      try {
        const response = await post({ "total_pay": totacart }, "linkpayrefacil");
        window.open(response.data.url, "_blank");


      } catch (error) {
        console.error(error);
      }
    };
    
    fetchData();
  };
  const handleGoRegalo = () => {
    navigate("/home/PagoRegalo");
  };
  const handleGoCambiarMetodoPago = () => {
    navigate("/home/MetodosDePago");
  };

  const handleUpdate = () => {
    var cart = JSON.parse(localStorage.getItem('cartListrefacil'));
    setTotalcart(cart[0].cart.total_price)


    if (cart[0].cart.total_price <= userPoints.total) {
      setisVisiblePayRewards(true);
    } else {
      setisVisiblePayRewards(false);
    }


    if (cart[0].cart.cantidad_total == 0) {
      setTotalcart(0)
      setisVisiblePayRewards(false);
      setisVisiblePayu(false);
    }
  };


  const go_bonos = () => {
    navigate("/home/Refacil?record=pineshome&category_code=pins&category_name=Pines");
  }
  return (
    <div className="container">
      <div>
        <Headerback title="Tus compras" />
      </div>

      <div style={{ height: "calc(70vh - 100px)" }}>
        {totacart > 0 ? (<div>


          <div className="row">
            <div className="col mt-5 inter_14_gray left ml-2 ">
              <span> Metodos de pago </span>
            </div>
            <div
              className="col mt-5 inter_14_gray right mr-2"
              style={{ color: "#53B5F6" }}
            >
              {/*<span onClick={handleGoCambiarMetodoPago}>Cambiar</span>*/}
            </div>
          </div>
          <div className="container ml-2">
            <div className="col-12 row  d-flex">
              <div className="col-3 d-flex align-items-center">
                <img
                  width="48"
                  height="48"
                  src={process.env.PUBLIC_URL + "/images/icon_pse.jpg"}
                  alt="mastercard"
                />
              </div>
              <div className="col inter_14_gray p-0 left mt-2   ">
                <span>PSE ACH</span>
                <br />
                <span className="inter_12 gray mt-3 align-items-left">

                </span>
              </div>
              <div className="col-3 mr-4 mt-3">
                <p className="inter_14_gray ml-2 "> </p>

              </div>
            </div>
          </div>


          <hr
            className="mx-2"
            style={{
              border: "none",
              height: "2px",
              background:
                "linear-gradient(to right, transparent, #53B5F6, transparent)",
            }}
          />
          <div className="container ml-2">
            <div className="col-12 row  d-flex">
              <div className="col-3 d-flex align-items-center">
                <img
                  width="48"
                  height="48"
                  src="../images/logo_letra_azul.png"
                  alt="puntos"
                />
              </div>
              <div className="col inter_14_gray p-0 left mt-3 ">
                <span>Puntos Fleksi</span>
              </div>
              <div className="col d-flex  justify-content-space-start mt-3">
                <p className="inter_14_gray ml-2 "> {userPoints.total} </p>
                <input type="checkbox" className="mb-3 ml-3" checked={isChecked} onChange={handleCheckboxChange} />
              </div>
            </div>
          </div>
          <hr
            className="mx-2"
            style={{
              border: "none",
              height: "2px",
              background:
                "linear-gradient(to right, transparent, #53B5F6, transparent)",
            }}
          />
          <div style={{ height: `calc(100vh - 120px)`, overflow: "scroll" }}>
            <ItemProductRefacilCart onUpdate={handleUpdate} />
          </div>

          <div className="container p-0 m-0" style={{ position: "fixed", bottom: "0px" }} >
            <hr
              className="mx-2"
              style={{
                border: "none",
                height: "2px",
                background:
                  "linear-gradient(to right, transparent, #53B5F6, transparent)",
              }}></hr>
            <div className="row  m-0 p-0 mt-2" style={{ width: "95%" }}>
              <p className="col align-items-center" style={{ fontSize: "18px" }}>
                Total:
                {isChecked && (totacart - userPoints.total >= 0 ?
                  numeral(totacart - userPoints.total).format("$0,0") : numeral(userPoints.total).format("$0,0"))}
                {!isChecked && numeral(totacart).format("$0,0")}
              </p>
              <button style={{ width: "80px", minWidth: "80px" }} className=" button-primary " onClick={handleGoPagar}>
                Pagar
              </button>
            </div>
          </div>
        </div>

        ) : (
          <div className='mt-2 text-center'>

            <p className='text-center py-5  px-3'>🛒 Tu carrito está vacío 🛒
              ¡Parece que aún no has añadido productos a tu carrito! Explora nuestro catálogo y descubre todo lo que tenemos para ti.
            </p>
            <p className='text-center py-3' style={{ display: "inline-block" }}>
              <button type="button" onClick={go_bonos} className="button-primary">🔍 ¡Explora!</button>
            </p>
          </div>
        )}

      </div>


    </div>
  );
};

export default Refacilcart;
