import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loginfire, post, postarraynoauth, postReponseregister } from '../../../apiService';
import { initializeApp } from "firebase/app"
import { signOut, signInWithCredential, onAuthStateChanged, GoogleAuthProvider, getAuth, getRedirectResult } from "firebase/auth";
import isMobile from 'is-mobile';
import { GoogleLogin } from '@react-oauth/google';
import ColorPages from '../../layouts/Colorpages';
import { googleLogout } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import NotificationModal from '../../layouts/NotificationModal';
import { ref } from 'firebase/database';

const Register = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyDOQzXakac4NBzXG2D0POgSvmHivNTXOJw",
        authDomain: "fleksi-24f8f.firebaseapp.com",
        projectId: "fleksi-24f8f",
        storageBucket: "fleksi-24f8f.appspot.com",
        messagingSenderId: "356390905973",
        appId: "1:356390905973:web:7f1a0d65183a09e004703c",
        measurementId: "G-LMT6PYZ553"
    };
    // Initialize Firebase and Firebase Authentication
    const app = initializeApp(firebaseConfig)
    const auth = getAuth(app);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var referido = localStorage.getItem('referido');


    let navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailFocused, setEmailFocused] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [nombresFocused, setNombresFocused] = useState(false);
    const [nombresError, setNombresError] = useState('');
    const [nombres, setNombres] = useState('');
    const [accepted, setAccepted] = useState(true);
    const [terminosError, setTerminosError] = useState('');
    const [codigoReferido, setCodigoReferido] = useState('');
    const [codigoReferidoFocused, setCodigoReferidoFocused] = useState(false);
    const [vreferido, setvreferido] = useState(true);
    const [nameusercode, setNameusercode] = useState('');
    const [showValidation, setShowValidation] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [dataNotification, setdataNotification] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [TermsConditions, setTermsConditions] = useState({
        authorization: false,
        terms: false,
        accepted: true,
        visible: false

    });
    const referidoas = async () => {
        if (referido != null) {
            setvreferido(false);
        }

        setCodigoReferido(referido);
        setCodigoReferidoFocused(true);
    };
    useEffect(() => {

        referidoas();
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleRadioChange = (option) => {
        setSelectedOption(option);
    };

    const handleTerminosChange = (event) => {
        setTermsConditions((prevTermsConditions) => ({
            ...prevTermsConditions,
            [event]: !prevTermsConditions[event],
            accepted: !prevTermsConditions[event] && prevTermsConditions.accepted,
        }));
    }
    const handleTouchStart = (event) => {
        event.preventDefault();
    };

    const handleTouchMove = (event) => {
        event.preventDefault();
    };

    const handleWheel = (event) => {
        event.preventDefault();
    };

    const goRegister = () => {
        googleLogout();
        signOut(auth)
        navigate("/Session/Login");
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'nombres') {
            setNombres(value);
        } else if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name === 'codigoReferido') {
            setCodigoReferido(value);
        }
    };

    const handleInputFocus = (event) => {
        const { name } = event.target;

        if (name === 'email') {
            setEmailFocused(true);
        } else if (name === 'password') {

            setPasswordFocused(true);
        } else if (name === 'nombres') {
            setNombresFocused(true);
        } else if (name === 'codigoReferido') {
            setCodigoReferidoFocused(true);
        }
    };

    const handleInputBlur = (event) => {
        const { name, value } = event.target;

        if (name === 'nombres') {
            setNombresError(value.trim() === '' ? 'Este campo es obligatorio' : '');
            setNombresFocused(false);
            if (value !== '') {
                setNombresFocused(true);
            }
        }

        if (name === 'email') {
            setEmailError(value.trim() === '' ? 'Este campo es obligatorio' : '');
            setEmailFocused(false);
            if (value !== '') {
                setEmailFocused(true);
            }
        }
        else if (name === 'password') {
            setPasswordError(value.trim() === '' ? 'Este campo es obligatorio' : '');
            setPasswordFocused(false);
            if (value !== '') {
                setPasswordFocused(true);
                if (value.length < 8 || !/[A-Z]/.test(value)) {
                    setPasswordError('La contraseña debe tener al menos 8 caracteres y una mayúscula');
                }
            }
        } else if (name === 'codigoReferido') {
            getUser();
            setCodigoReferidoFocused(false);
            if (value !== '') {
                setCodigoReferidoFocused(true);
            }
        }

        // Validación del formato de email
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
            setEmailError('Ingrese un email válido');
            return;
        } else {
            setEmailError('');
        }


    };
    const go_terminos = async () => {

        navigate("/Session/Register/Terminosycondiciones");

    }

    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    const handleLogin = async () => {

        let hasError = false;
        setShowValidation(true);
        getUser();


        if (nombres.trim() === '') {
            setNombresError('Este campo es obligatorio');
            hasError = true;
        } else {
            setNombresError('');
        }

        if (email.trim() === '') {
            setEmailError('Este campo es obligatorio');
            hasError = true;
        } else {
            setEmailError('');
        }

        if (password.trim() === '') {
            setPasswordError('Este campo es obligatorio');
            hasError = true;
        } else if (password.length < 8 || !/[A-Z]/.test(password)) {
            setPasswordError('La contraseña debe tener al menos 8 caracteres y una mayúscula');
            hasError = true;
        } else {
            setPasswordError('');
        }
        // Validación del formato de email
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
            setEmailError('Ingrese un email válido');
            hasError = true;
        } else {
            setEmailError('');
        }

        if (!TermsConditions.accepted) {
            setTerminosError('¡Atención! Debes aceptar los términos y condiciones para continuar.');
            hasError = true;
        } else {
            setTerminosError('')
        }

        if (hasError == false) {
            //crear  usuario // validando la cuenta  inmediantamente 
            //no necesita validacion.
            const errroResponse = (error) => {
                setEmailError(error.error)
            };

            const create_user_email = await postReponseregister({
                referral_code: codigoReferido,
                email: email,
                password: password,
                first_name: nombres,
                id_cities: 2,
                terms: TermsConditions.terms ? 1 : 2,
                authorization: TermsConditions.authorization ? 1 : 2,
                idaliado: localStorage.getItem('code_aliado')
            }, "create_user_email");

            if (create_user_email.code == 200) {
                localStorage.setItem('accessToken', create_user_email.data.token);
                navigate("/home");
            } else {

                setdataNotification(create_user_email.data);
                setShowNotification(true);

            }


        }
    };


    const responseMessage = async (response) => {
        // Build Firebase credential with the Google ID token.
        const idToken = response.credential;
        const credential = GoogleAuthProvider.credential(idToken);
        var decodedHeader = jwt_decode(response.credential);
        const { name, email } = decodedHeader;

        setEmail(email);
        setEmailFocused(true)
        setNombres(name);
        setNombresFocused(true);

        try {
            // Sign in with credential from the Google user.
            const userCredential = await signInWithCredential(auth, credential);
            const user = userCredential.user;

            // Aquí puedes acceder a la información del usuario autenticado
            if (user) {
                user.getIdToken().then((idToken) => {
                    const tokenobtenido = async (token) => {
                        if (token != "") {
                            localStorage.setItem('accessToken', token);
                            navigate('/home');
                        } else {

                            const errroResponse = (error) => {
                                setEmailError(error.error)
                            };
                            const create_external_user = await postarraynoauth({
                                referral_code: codigoReferido,
                                email: email,
                                password: "xxxxxxx",
                                first_name: nombres,
                                Firebasetoken: idToken,
                                id_cities: 2,
                                idaliado: localStorage.getItem('code_aliado')
                            }, "create_external_user", errroResponse);

                            localStorage.setItem('accessToken', create_external_user.token);
                            navigate('/home');
                        }

                    };
                    loginfire({ Firebasetoken: idToken }, tokenobtenido);
                });
                // Ahora puedes realizar acciones adicionales con la información del usuario
            }
        } catch (error) {
            console.error("Error al autenticar con Firebase:", error);
        }

    };


    const errorMessage = (error) => {
        console.log(error);
    };


    const getUser = async () => {
        setNameusercode('')
        if (codigoReferido != "") {
            try {
                const userinfo = await postarraynoauth({ "prospectus_code": codigoReferido }, "prospectcodeinfo");

                setNameusercode(userinfo.first_name);
            } catch (error) {
                setNameusercode('')
            }
        }
    };



    return (
        <div className="bg-white">
            {showNotification && (
                <NotificationModal
                    message={dataNotification}
                    onClose={handleCloseNotification}
                />
            )}
            <ColorPages />


            <div className="center-content" style={{ marginTop: "120px" }}>
                <label className='title-bold w-100   font-40'>¡HOLA!</label>
                <label className='subtitle font-18 m-20 w-100' >Queremos conocerte</label>
            </div>

            <form>
                {vreferido && (<div className="center-content w-100" style={{ marginTop: "50px" }}>
                    <div className={`w-75 form-input-container  ${codigoReferidoFocused ? 'input-filled' : ''}`}>
                        <label className="form-label">Código de Referido</label>
                        <input
                            type="text"
                            name="codigoReferido"
                            value={codigoReferido}
                            onFocus={handleInputFocus}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            className="form-input"
                        />
                        <div className='inter_12_500 mt-1 w-100 left valid'>{nameusercode}</div>
                    </div>
                </div>)}
                <div className="center-content w-100">
                    <div className={`w-75 form-input-container ${nombresFocused ? 'input-filled' : ''}`}>
                        <label className="form-label">Nombres</label>
                        <input
                            type="text"
                            name="nombres"
                            value={nombres}
                            onFocus={handleInputFocus}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            className="form-input disable-zoom"
                            id="nombres"
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onWheel={handleWheel}
                        />
                        {nombresError && <span className="error-label">{nombresError}</span>}
                    </div>
                    <div className={`w-75 form-input-container ${emailFocused ? 'input-filled' : ''}`}>
                        <label className="form-label">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            className="form-input"
                        />
                        {emailError && <span className="error-label">{emailError}</span>}
                    </div>
                    <div className={`w-75 form-input-container ${passwordFocused ? 'input-filled' : ''}`}>

                        <div className="password-input-container ">
                            <label className="form-label">Contraseña</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={password}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                className="form-input form-input"
                            />
                            <span className="password-toggle" onClick={togglePasswordVisibility}>
                                <img style={{ width: "25px" }}
                                    src={showPassword ? process.env.PUBLIC_URL + '/images/showpassword.png' : process.env.PUBLIC_URL + '/images/hidepassword.png'}
                                    alt={showPassword ? 'Show Password' : 'Hide Password'}
                                />
                            </span>
                        </div>
                        {passwordError && <span className="error-label">{passwordError}</span>}


                    </div>

                    <div className='inter_12_500' style={{ marginTop: '5px' }}>Si ya tienes una cuenta creada por favor <span onClick={goRegister} className='registrate_line'>
                        inicia sesión aquí</span></div>

                    <div className='row'>


                        <div className='inter_12_500 d-inline mt-2  col-12 center'>
                            <input
                                type="radio"
                                checked={TermsConditions.terms}
                                onChange={() => handleTerminosChange('terms')}
                            />
                            <div className='ml-2'>Acepto los términos y condiciones </div>
                        </div>
                        <div className='inter_12_500 d-inline mt-2 col-12 center'>
                            <label className="inter_12_500 color-primary" onClick={go_terminos}> Ver términos y condiciones   </label>
                        </div>


                        <div className='inter_12_500 d-inline px-5'>
                            {terminosError && <label className="inter_12_500 color-red">{terminosError}</label>}
                        </div>
                    </div>
                    <div className='row col-12'>
                        <div className='col  m-0 p-0 right'><img className="w-75" src={process.env.PUBLIC_URL + '/images/linerect.png'} alt="Imagen" /> </div>
                        <div className='col-1 pt-1'><img style={{ height: "12px", width: "12px" }} src={process.env.PUBLIC_URL + '/images/ellipse.png'} alt="Imagen" /> </div>
                        <div className='col  m-0 p-0 left'><img className="w-75" src={process.env.PUBLIC_URL + '/images/linerect.png'} alt="Imagen" /> </div>
                    </div>
                    <button type="button" onClick={handleLogin} className="button-primary">Inicia sesión</button>
                    <div style={{ marginTop: "17px" }} ></div>
                    <GoogleLogin text="continue_with" width='291px' onSuccess={responseMessage} onError={errorMessage} />
                </div>
            </form>

        </div>
    );
}

export default Register;
