import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import numeral from 'numeral';
import useCart from '../../components/utils/useCart';

const ItemProductBonocart = ({onUpdate}) => {
  const navigate = useNavigate();
  const { addToCart, removeFromCart, cartList, total_cart ,get_cart} = useCart();

  const [products, setProducts] = useState([]); // Estado para almacenar los datos del carrito

  useEffect(() => {
    if (cartList.length > 0) {
      setProducts(cartList[0].products);
    } else {
      setProducts([]);
      if (onUpdate) onUpdate();
    }
  }, [cartList]);

  const incrementarValor = (item) => {
    item.quantity += 1;
    item.total_price = item.quantity * item.price;

    addToCart({
      id_items: item.id_items,
      actual_code_product: item.actual_code_product,
      title: item.title,
      quantity:  item.quantity ,
      price: item.price,
      total_price: item.total_price,
      image: item.image,
  });// Actualizar estado para forzar la re-renderización
 

  const updatedProducts = products.map((product) => {
    if (product.id_items === item.id_items) {
      return {
        ...product,
        quantity: product.quantity ,
        total_price: (product.quantity) * product.price,
      };
    }
    return product;
  });

  setProducts(updatedProducts);
  if (onUpdate) onUpdate();
//no funciona no actualiza  el   item.quantity   en el html
  };

  const decrementarValor = (item) => {
    item.quantity -= 1;
    if(item.quantity ==0){
      removeFromCart({actual_code_product:item.actual_code_product});
      setProducts(cartList[0].products);
      if (onUpdate) onUpdate();
    }

    if(item.quantity >0){
    item.total_price = item.quantity * item.price;

    addToCart({
      id_items: item.id_items,
      actual_code_product: item.actual_code_product,
      title: item.title,
      quantity:  item.quantity ,
      price: item.price,
      total_price: item.total_price,
      image: item.image,
  });// Actualizar estado para forzar la re-renderización
 

  const updatedProducts = products.map((product) => {
    if (product.id_items === item.id_items) {
      return {
        ...product,
        quantity: product.quantity ,
        total_price: (product.quantity) * product.price,
      };
    }
    return product;
  });

  setProducts(updatedProducts);
  if (onUpdate) onUpdate();
}
    
  };

  const eliminarItem = (item) => {
    removeFromCart({actual_code_product:item.actual_code_product});
    setProducts(cartList[0].products);
    if (onUpdate) onUpdate();
  };

  return (
    <div className="container card-rounded-comercios">
      {products.map((item) => (
        <div key={item.id_items} className="col-12 row mb-4">
          <div className="col-3 d-flex align-items-center">
            <div className="card_gray_products center" style={{ height: "82px", width: "82px" }}>
              <img
                src={item.image}
                style={{ width: 'auto', height: '70px', objectFit: 'contain' }}
                className="img-fluid"
                alt="Imagen"
              />
            </div>
          </div>
          <div className="col">
            <div
              className="inter_14_500 gray-dark mb-2 left mx-0 px-0"
              style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {item.title}
            </div>
            <div className="inter_14_600 gray-dark  left mx-0 px-0 row col-12">
              <div style={{ marginTop: "18px" }} className="col  justify-content-start px-0  justify-content-end">
                {numeral(item.total_price).format('$0,0')} pesos
              </div>
              <div className="col  justify-content-end m-0 p-0">
                <div className='row m-0 ' style={{ marginTop: "15px" }}>
                  <div className="bono-redondo">
                    <div className="botones center">
                      <button className="boton-menos-cart" onClick={() => decrementarValor(item)}> - </button>
                      <div className="inter_14_400_normal gray-dark">{item.quantity}</div>
                      <button className="boton-mas-cart" onClick={() => incrementarValor(item)}> + </button>
                      <button className="boton-remove-cart" onClick={() => eliminarItem(item)}>
                        <img style={{ width: "14px" }} src={process.env.PUBLIC_URL + '/images/remove.png'} alt="Buscar" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemProductBonocart;