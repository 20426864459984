import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import ScrollContainer from "../utils/ScrollContainer";

const ItemProductBono = ({ title, data }) => {
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  const handleCardClick = (code) => {
    if (isDragging) {
      return;
    }
    navigate(`/home/Bono?code=${code}`);
  };

  return (
    <div className="mt-0 pt-0 my-0 py-0">
      {data.length > 0 && (
        <div className="inter_14 gray-dark left ml-2">{title}</div>
      )}
      <ScrollContainer onDragChange={setIsDragging}>
        <div style={{ height: "auto", width: "auto", display: "inline-flex" }}>
          {data.map((item) => (
            <div
              className=" m-2 card_back gap-0 center"
              key={item.id_fleksi_products}
              onClick={() => handleCardClick(item.id_fleksi_products)}
            >
              <div
                className="card-products center"
                style={{ backgroundColor: item.background }}
              >
                <div className="img-top-round">
                  <img src={item.image} className="" alt="Imagen" />
                </div>
                <span className="overlay-text bg-yellow gray-dark">Bonos</span>
                {item.discount !== "" && (
                  <>
                    <span className="overlay-promo-text color-white">
                      {item.discount}
                    </span>
                  </>
                )}
              </div>
              <span
                className="inter_14_600 gray-dark mt-2 mb-3 px-2 w-100 left rm-2"
                style={{
                  maxWidth: "250px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.ecommerce}
              </span>
              <span
                className="inter_12 gray-dark  px-2 mb-3 w-100 left rm-5"
                style={{
                  maxWidth: "250px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.subtitle}
              </span>
              <span className="inter_14_600 gray-dark mb-3 px-2 w-100 left rm-2" 
              style={{
                maxWidth: "250px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              >
                {numeral(item.valor_de_venta).format("0") !== "0" ? (
                  <div>{numeral(item.valor_de_venta).format("$0,0")} pesos</div>
                ) : (
                  <div style={{ color: "white" }}>.</div>
                )}
              </span>
              <span className="inter_14_600_green px-2 mb-2 w-100 left  rm-2"
              style={{
                maxWidth: "250px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              >
                Hasta {item._return_to_presentation_users}% Cashback
              </span>
            </div>
          ))}
        </div>
      </ScrollContainer>
    </div>
  );
};

export default ItemProductBono;
