import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postarray } from '../../apiService';
import ImageComponent from './ImageComponent';


const HeaderTiendaFisica = ({ title }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCardClick = (code) => {
 
    const randomNumber = Math.floor(Math.random() * 1000);
    setShowSuggestions(false)
    navigate(`/home/TiendaProductoFisico?code=${code}&rand=${randomNumber}`);
 
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [resultados, setResultados] = useState([]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    try {
      const response = await postarray( { searchTerm: value },'searchProductstiendafisica');
      console.log(response);
      setSuggestions(response.results);
      setShowSuggestions(value !== '');
    } catch (error) {
      console.error(error);
    }
  };

  const renderSuggestions = () => {
    if (!showSuggestions) {
      return null;
    }

    return (
      <ul className="suggestions-list mt-2">
        {suggestions.map((suggestion, index) => (
          <li  className='left' key={index}  onClick={() => handleCardClick(suggestion.id_fleksi_products)}>
   
            <ImageComponent
                        src={suggestion.image}
                        alt={suggestion.title}
                        defaultSrc={process.env.PUBLIC_URL + '/images/defaultimage.png'}
                        cssclass={'img-search'} />

            <span  className='inter_12_500 ml-2'  style={{marginTop:'15px', position:'absolute'}}>  {suggestion.title}</span>
          </li>
        ))}
      </ul>
    );
  };



  return (
    <div  className='w-100 bg-white pt-3' style={{ position: 'fixed', top: 0, zIndex: 999 }}>
    <div className="banner mb-3">
    <div className="left-div mt-3" onClick={handleGoBack}>
        <img
            src={process.env.PUBLIC_URL + '/images/img-back.png'}
            alt="Slider 1"
            style={{ width: '28px', height: 'auto' }}
        />
    </div>
    <div className="center-div">
        <div className="search-container">
            <span className="search-icon">
                <img src={process.env.PUBLIC_URL + '/images/buscar.png'} alt="Buscar" />
            </span>
            <input
                style={{ marginTop: '10px', paddingLeft: '40px' }}
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                className="form-input form_rounded"
                placeholder="Buscar aquí"
            />
        </div>
    </div>
    <div className="right-div  pt-3">
 
    </div>
    {renderSuggestions()}
</div>
</div>
  );
};

export default HeaderTiendaFisica;
