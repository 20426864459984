import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ItemProductBono from '../layouts/ItemProductBono';
import HeaderEcommerce from '../layouts/HeaderEcommerce';
import { getdata, post, postarray, postsingle } from '../../apiService';
import Headerback from '../layouts/Headerback';
import ItemProductTienda from '../layouts/ItemProductTienda';
import ColorPages from '../layouts/Colorpages';
import HeaderTiendaFisica from '../layouts/HeaderTiendaFisica';


const TiendaComercio = () => {
    const [bono, setBono] = useState({});
    const [productos, setProductos] = useState([]);
    const [productostwo, setProductostwo] = useState([]);
    const [cashbackespecial, setCashbackespecial] = useState([]);
    const [cashbackespecialtwo, setCashbackespecialtwo] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    const fetchData = async () => {
        try {
            const bonoReponse = await postarray({ "id_items": code }, "tiendaComercio");
            setBono(bonoReponse);
            setCashbackespecial(bonoReponse.cashbackespecial.productsone);
            setCashbackespecialtwo(bonoReponse.cashbackespecial.productstwo);
            setProductos(bonoReponse.products.productsone);
            setProductostwo(bonoReponse.products.productstwo);

        } catch (error) {
            console.error(error);
        }
        return () => {}
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
                   <ColorPages />
            <HeaderTiendaFisica title="" />
            <div className='page'>-
                <div className="image-container-comercio mt-3">
                    <img
                        className="centered-image-comercio"
                        src={bono.image}
                        alt={bono.title}
                    />
                </div>
                <div className=' container w-100'>
                    <div className=' inter_30_700 gray-dark w-100 left'>{bono.title}</div>
                    <div className='inter_12 gray w-100 left mt-2'>{bono.subtitleapp}</div>
                </div>

                <div style={{ marginTop: "40px" }}>
                    <ItemProductTienda title="Productos destacados" data={productos} />
                </div>
                <div style={{ marginTop: "40px" }}>
                    {productostwo.length > 0 && <ItemProductTienda title="" data={productostwo} />}
                </div>
                <div style={{ marginTop: "40px" }}>
                    <ItemProductTienda title="Cashbacks especiales para ti" data={cashbackespecial} />
                </div>
                <div style={{ marginTop: "40px" }}>
                    {cashbackespecialtwo.length > 0 && <ItemProductTienda title="" data={cashbackespecialtwo} />}
                </div>
            </div>
        </div>

    );
};

export default TiendaComercio;
