// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

h1 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.download-links {
  margin-top: 20px;
}

.download-links img {
  width: 180px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.download-links img:hover {
  transform: scale(1.05);
  opacity: 0.9;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,8BAA8B;EAC9B,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd","sourcesContent":["body {\n  margin: 0;\n  font-family: Arial, sans-serif;\n  background-color: #f4f4f4;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  text-align: center;\n}\n\nh1 {\n  color: #333;\n  font-size: 2.5rem;\n  margin-bottom: 20px;\n}\n\np {\n  color: #666;\n  font-size: 1.2rem;\n  margin-bottom: 10px;\n}\n\n.download-links {\n  margin-top: 20px;\n}\n\n.download-links img {\n  width: 180px;\n  margin: 10px;\n  cursor: pointer;\n  transition: transform 0.2s ease;\n}\n\n.download-links img:hover {\n  transform: scale(1.05);\n  opacity: 0.9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
