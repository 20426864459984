import React, { useEffect, useState } from "react";
import Headerback from "../layouts/Headerback";
import ItemComercios from "../layouts/ItemComercios";
import { getdata } from "../../apiService";
import ItemProduct from "../layouts/ItemProduct";
import ColorPages from "../layouts/Colorpages";

const Suscripciones = () => {
  const [seleccionado, setSeleccionado] = useState("tienda");
  const [comercios, setComercios] = useState([]);
  const [comerciostwo, setComerciostwo] = useState([]);
  const [cashbackespecial, setCashbackespecial] = useState([]);
  const [cashbackespecialtwo, setCashbackespecialtwo] = useState([]);
  const [lomasnuevo, setLomasnuevo] = useState([]);
  const [lomasnuevotwo, setLomasnuevotwo] = useState([]);
  const [tepuedeinteresar, setTepuedeinteresar] = useState([]);
  const [tepuedeinteresartwo, setTepuedeinteresartwo] = useState([]);
  const handleClick = (opcion) => {
    setSeleccionado(opcion);
  };

  const fetchData = async () => {
    try {
      const comerciosReponse = await getdata("comercios_aliados");
      setComercios(comerciosReponse.data.shops);
      setComerciostwo(comerciosReponse.data.shopstwo);

      const homeresponse = await getdata("home");
      setCashbackespecial(homeresponse.cashbackespecial.productsone);
      setLomasnuevo(homeresponse.lomasnuevo.productsone);
      setTepuedeinteresar(homeresponse.tepuedeinteresar.productsone);

      setCashbackespecialtwo(homeresponse.cashbackespecial.productstwo);
      setLomasnuevotwo(homeresponse.lomasnuevo.productstwo);
      setTepuedeinteresartwo(homeresponse.tepuedeinteresar.productstwo);
    } catch (error) {
      console.error(error);
    }
    return () => {}
  };


  useEffect(() => {   
    fetchData();
  }, []);

  return (
    <div className="container">
      <div>
        <ColorPages />
        <Headerback title="Suscripciones" />
      </div>
      <div className="row p-0 mt-5 mr-2 ml-2 tag_suscripcions align-items-center">
        <div
          className={`col opcion_tag ${
            seleccionado === "tienda" ? "seleccionado" : ""
          }`}
          onClick={() => handleClick("tienda")}
        >
          <span className="inter_14_600 ">Tienda</span>
        </div>

        <div
          className={`col opcion_tag ${
            seleccionado === "suscripciones" ? "seleccionado" : ""
          }`}
          onClick={() => handleClick("suscripciones")}
        >
          <span className="inter_14_600 ">Mis suscripciones</span>
        </div>
      </div>
      {seleccionado === "tienda" && (
        <div>
          <div style={{ marginTop: "20px" }}>
            <ItemComercios title="Comercios aliados" data={comercios} />
            {comerciostwo.length > 0 && (
              <ItemComercios title="" data={comerciostwo} />
            )}
          </div>

          <div>
            <ItemProduct
              title="Cashbacks especiales para ti"
              data={cashbackespecial}
            />
            {cashbackespecialtwo.length > 0 && (
              <ItemProduct title="" data={cashbackespecialtwo} />
            )}

            <ItemProduct title="Te puede interesar" data={tepuedeinteresar} />
            {tepuedeinteresartwo.length > 0 && (
              <ItemProduct title="" data={tepuedeinteresartwo} />
            )}
            <ItemProduct title="Lo mas nuevo" data={lomasnuevo} />
            {lomasnuevotwo.length > 0 && (
              <ItemProduct title="" data={lomasnuevotwo} />
            )}
          </div>
        </div>
      )}
      {seleccionado === "suscripciones" && (
        <div className="container">
          <div className="px-3">
            <div
              className="gift-card mt-4 pb-2 row "
              style={{ borderRadius: "10px 10px 0 0" }}
            >
              <div className="col  body">
                <p className="inter_14_600 left ">Agregar Suscripcion</p>
              </div>
            </div>
            <div
              className="row footer d-flex align-items-center justify-content-start inter_14 gray "
              style={{
                zIndex: 0,
                borderRadius: "10px 10px 0 0",
                backgroundColor: "#1DB954",
                height: "100px",
              }}
            >
              <div className="col-9">
                <div className="ml-2 d-flex align-items-center justify-content-start inter_20_normal color-white">
                  <span>Spotify</span>
                </div>

                <button
                  className="m-3 align-items-center justify-content-start p-1"
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: "3px",
                    width: "90px",
                  }}
                >
                  $11,900/Mes
                </button>
              </div>
              <div className="col-3">
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/FFFFFF/48/spotify.png"
                  alt="spotify"
                  style={{ borderRadius: "67px" }}
                />
              </div>
            </div>
            <div
              className="row  footer d-flex align-items-center justify-content-start "
              style={{
                backgroundColor: "#E50914",
                zIndex: 1,
                borderRadius: "10px",
                height: "100px",
              }}
            >
              <div className="col-9">
                <div className="ml-2 d-flex align-items-center justify-content-start inter_20_normal  color-white">
                  <span>Netflix</span>
                </div>
                <button
                  className="m-3 align-items-center justify-content-start p-1"
                  style={{
                    backgroundColor: "white",
                    border: "none",
                    borderRadius: "3px",
                    width: "90px",
                  }}
                >
                  $11,900/Mes
                </button>
              </div>
              <div className="col-3">
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/48/external-netflix-an-american-video-on-demand-service-logo-shadow-tal-revivo.png"
                  alt="external-netflix-an-american-video-on-demand-service-logo-shadow-tal-revivo"
                  style={{ borderRadius: "67px", backgroundColor: "#000000" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Suscripciones;
