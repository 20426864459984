import React, { useEffect } from 'react';

const NotificationModal = ({ message, onClose }) => {
  const icon = localStorage.getItem('icon_notificacion');
  useEffect(() => {


    // Configurar un temporizador para cerrar el modal después de 2 segundos
    const timer = setTimeout(() => {
      //onClose();
    }, 2000);

    // Limpiar el temporizador cuando el componente se desmonte o cuando onClose cambie
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content-push">
        <div className='row col-12 center'>
          <div className='col-2 pl-3'>
            <img src={icon} alt="Buscar" style={{ width: "40px" }} />
          </div>
          <div  style={{ color:'#393F42' }} className='col  left  inter_12_400'>
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
